import { Machine, assign, Interpreter, AnyEventObject } from "xstate";
import { AxiosError, CancelTokenSource } from "axios";
import { CreateToastFnReturn } from "@chakra-ui/react";

import { promiseWRetry } from "utils/helpers";
import { getCancelSource } from "utils/url_helpers";
import { PITCH_GROUP_OVERALL } from "_react/shared/_constants/pitch_types";
import { displayAxiosErrorToast } from "_react/shared/_helpers/axios";
import { fetchPlayerSeasonArsenalScoresByTeam } from "_react/shared/data_models/arsenal_scores/_network";
import { IPlayerSeasonArsenalScoresByTeamSchema } from "_react/shared/data_models/arsenal_scores/_types";
import { BATS_R, BATS_L } from "_react/shared/data_models/baseline_hit_probs/_constants";
import { COUNT_SPLIT_OVERALL, SCHEMA_TYPE_EXPANDED } from "_react/shared/data_models/arsenal_scores/_constants";
import { fetchPitchOutcomeObservedPitchByTeam } from "_react/shared/data_models/pitch_outcome_observed/_network";
import { IPitchOutcomeObservedPitchByTeam } from "_react/shared/data_models/pitch_outcome_observed/_types";
import { IPitchOutcomeProbabilitiesPitchByTeam } from "_react/shared/data_models/pitch_outcome_probabilities/_types";
import { fetchPitchOutcomeProbabilitiesPitchByTeam } from "_react/shared/data_models/pitch_outcome_probabilities/_network";
import { PLAYING_LEVEL_AMA } from "_react/shared/data_models/seasonal_grades/_constants";

import { TPitcherPitchOutcomesTableData } from "_react/shared/ui/data/tables/AmaPitcherPitchOutcomesTable/_types";

export const PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_CANCEL_SOURCE = "playerSeasonArsenalScoresByTeam";
export const PLAYER_SEASON_POP_PITCH_BYTEAM_CANCEL_SOURCE = "playerSeasonPopPitchByTeam";
export const PLAYER_SEASON_POO_PITCH_BYTEAM_CANCEL_SOURCE = "playerSeasonPooPitchByTeam";

export type TPitcherPitchOutcomesTableCancelSource = {
	[PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_CANCEL_SOURCE]?: CancelTokenSource;
	[PLAYER_SEASON_POP_PITCH_BYTEAM_CANCEL_SOURCE]?: CancelTokenSource;
	[PLAYER_SEASON_POO_PITCH_BYTEAM_CANCEL_SOURCE]?: CancelTokenSource;
};

export type TPitcherPitchOutcomesTableFilters = {
	bats: Array<string>;
	pitchTypes?: Array<string>;
	levels?: Array<string>;
	maxSeason?: number;
	minSeason?: number;
};

export type TPitcherPitchOutcomesTableContext = {
	playerId?: number;
	lastPlayerId?: number;
	shouldFetchData?: boolean;
	filters: TPitcherPitchOutcomesTableFilters;
	playerSeasonArsenalScoresByTeam?: Array<IPlayerSeasonArsenalScoresByTeamSchema> | null;
	playerSeasonPopPitchByTeam?: Array<IPitchOutcomeProbabilitiesPitchByTeam> | null;
	playerSeasonPooPitchByTeam?: Array<IPitchOutcomeObservedPitchByTeam> | null;
	cancelSources: TPitcherPitchOutcomesTableCancelSource;
	toast?: CreateToastFnReturn;
};

interface IPitcherPitchOutcomesTableStateSchema {
	states: {
		initializing: {};
		initialized: {
			states: {
				// Fetches all PoP Pitch by player season team
				playerSeasonPopPitchByTeam: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches all PoO Pitch by player season team
				playerSeasonPooPitchByTeam: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches all arsenal scores by player season team
				playerSeasonArsenalScoresByTeam: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
			};
		};
	};
}
export const FETCHING_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM = {
	initialized: { playerSeasonArsenalScoresByTeam: "fetching" }
};

export const FETCHING_PLAYER_SEASON_POP_PITCH_BYTEAM = {
	initialized: { playerSeasonPopPitchByTeam: "fetching" }
};

export const FETCHING_PLAYER_SEASON_POO_PITCH_BYTEAM = {
	initialized: { playerSeasonPooPitchByTeam: "fetching" }
};

const FETCH_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_DONE = "done.invoke.fetchPlayerSeasonArsenalScoresByTeam:invocation[0]";
const FETCH_PLAYER_SEASON_POP_PITCH_BYTEAM_DONE = "done.invoke.fetchPlayerSeasonPopPitchByTeam:invocation[0]";
const FETCH_PLAYER_SEASON_POO_PITCH_BYTEAM_DONE = "done.invoke.fetchPlayerSeasonPooPitchByTeam:invocation[0]";

const FETCH_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_ERROR =
	"error.platform.fetchPlayerSeasonArsenalScoresByTeam:invocation[0]";
const FETCH_PLAYER_SEASON_POP_PITCH_BYTEAM_ERROR = "error.platform.fetchPlayerSeasonPopPitchByTeam:invocation[0]";
const FETCH_PLAYER_SEASON_POO_PITCH_BYTEAM_ERROR = "error.platform.fetchPlayerSeasonPooPitchByTeam:invocation[0]";

export const SET_PLAYER_ID = "SET_PLAYER_ID";
export const SET_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM = "SET_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM";
export const SET_PLAYER_SEASON_POP_PITCH_BYTEAM = "SET_PLAYER_SEASON_POP_PITCH_BYTEAM";
export const SET_PLAYER_SEASON_POO_PITCH_BYTEAM = "SET_PLAYER_SEASON_POO_PITCH_BYTEAM";
export const SET_FILTERS = "SET_FILTERS";

type TFetchPlayerSeasonArsenalScoresByTeamEvent = {
	type: typeof FETCH_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_DONE;
	data: Array<IPlayerSeasonArsenalScoresByTeamSchema> | undefined;
};
type TFetchPlayerSeasonPopPitchByTeamEvent = {
	type: typeof FETCH_PLAYER_SEASON_POP_PITCH_BYTEAM_DONE;
	data: Array<IPitchOutcomeProbabilitiesPitchByTeam> | undefined;
};
type TFetchPlayerSeasonPooPitchByTeamEvent = {
	type: typeof FETCH_PLAYER_SEASON_POO_PITCH_BYTEAM_DONE;
	data: Array<IPitchOutcomeObservedPitchByTeam> | undefined;
};

type TFetchPlayerSeasonArsenalScoresByTeamErrorEvent = {
	type: typeof FETCH_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_ERROR;
	data?: AxiosError | Error;
};
type TFetchPlayerSeasonPopPitchByTeamErrorEvent = {
	type: typeof FETCH_PLAYER_SEASON_POP_PITCH_BYTEAM_ERROR;
	data?: AxiosError | Error;
};
type TFetchPlayerSeasonPooPitchByTeamErrorEvent = {
	type: typeof FETCH_PLAYER_SEASON_POO_PITCH_BYTEAM_ERROR;
	data?: AxiosError | Error;
};

type TSetPlayerIdEvent = { type: typeof SET_PLAYER_ID; value: number | undefined };
type TSetPlayerSeasonArsenalScoresByTeamEvent = {
	type: typeof SET_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM;
	value?: Array<IPlayerSeasonArsenalScoresByTeamSchema> | null;
};
type TSetPlayerSeasonPopPitchByTeamEvent = {
	type: typeof SET_PLAYER_SEASON_POP_PITCH_BYTEAM;
	value?: Array<IPitchOutcomeProbabilitiesPitchByTeam> | null;
};
type TSetPlayerSeasonPooPitchByTeamEvent = {
	type: typeof SET_PLAYER_SEASON_POO_PITCH_BYTEAM;
	value?: Array<IPitchOutcomeObservedPitchByTeam> | null;
};
type TSetFiltersEvent = {
	type: typeof SET_FILTERS;
	value: TPitcherPitchOutcomesTableFilters;
};

type TPitcherPitchOutcomesTableEvent =
	| TFetchPlayerSeasonArsenalScoresByTeamEvent
	| TFetchPlayerSeasonPopPitchByTeamEvent
	| TFetchPlayerSeasonPooPitchByTeamEvent
	| TFetchPlayerSeasonArsenalScoresByTeamErrorEvent
	| TFetchPlayerSeasonPopPitchByTeamErrorEvent
	| TFetchPlayerSeasonPooPitchByTeamErrorEvent
	| TSetPlayerIdEvent
	| TSetPlayerSeasonArsenalScoresByTeamEvent
	| TSetPlayerSeasonPopPitchByTeamEvent
	| TSetPlayerSeasonPooPitchByTeamEvent
	| TSetFiltersEvent;

export type TPitcherPitchOutcomesTableSend = Interpreter<
	TPitcherPitchOutcomesTableContext,
	IPitcherPitchOutcomesTableStateSchema,
	TPitcherPitchOutcomesTableEvent
>["send"];

const PitcherPitchOutcomesTableMachine = (
	playerIdProp?: number,
	data?: TPitcherPitchOutcomesTableData,
	shouldFetchDataProp = true,
	toastProp?: CreateToastFnReturn
) =>
	Machine<TPitcherPitchOutcomesTableContext, IPitcherPitchOutcomesTableStateSchema, TPitcherPitchOutcomesTableEvent>(
		{
			id: "pitcherPitchOutcomesTable",
			initial: "initializing",
			context: {
				playerId: playerIdProp,
				lastPlayerId: undefined,
				shouldFetchData: shouldFetchDataProp,
				filters: {
					bats: [BATS_L, BATS_R],
					pitchTypes: undefined,
					levels: undefined
				},
				playerSeasonArsenalScoresByTeam: data?.playerSeasonArsenalScoresByTeam,
				playerSeasonPopPitchByTeam: data?.playerSeasonPopPitchByTeam,
				playerSeasonPooPitchByTeam: data?.playerSeasonPooPitchByTeam,
				cancelSources: {},
				toast: toastProp
			},
			states: {
				initializing: {
					always: "initialized"
				},
				initialized: {
					type: "parallel",
					on: {
						SET_PLAYER_ID: {
							actions: [
								"setPlayerId",
								"clearPlayerSeasonArsenalScoresByTeam",
								"clearPlayerSeasonPopPitchByTeam",
								"clearPlayerSeasonPooPitchByTeam"
							],
							cond: "shouldSetPlayerId"
						},
						SET_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM: { actions: "setPlayerSeasonArsenalScoresByTeam" },
						SET_PLAYER_SEASON_POP_PITCH_BYTEAM: { actions: "setPlayerSeasonPopPitchByTeam" },
						SET_PLAYER_SEASON_POO_PITCH_BYTEAM: { actions: "setPlayerSeasonPooPitchByTeam" },
						SET_FILTERS: { actions: "setFilters" }
					},
					states: {
						playerSeasonArsenalScoresByTeam: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchPlayerSeasonArsenalScoresByTeam",
												cond: "shouldFetchPlayerSeasonArsenalScoresByTeam"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchPlayerSeasonArsenalScoresByTeam",
									entry: ["refreshPlayerSeasonArsenalScoresByTeamCancelSource"],
									invoke: {
										src: "fetchPlayerSeasonArsenalScoresByTeam",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchPlayerSeasonArsenalScoresByTeamSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchPlayerSeasonArsenalScoresByTeamErrored"
										}
									}
								}
							}
						},
						playerSeasonPopPitchByTeam: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchPlayerSeasonPopPitchByTeam",
												cond: "shouldFetchPlayerSeasonPopPitchByTeam"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchPlayerSeasonPopPitchByTeam",
									entry: ["refreshPlayerSeasonPopPitchByTeamCancelSource"],
									invoke: {
										src: "fetchPlayerSeasonPopPitchByTeam",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchPlayerSeasonPopPitchByTeamSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchPlayerSeasonPopPitchByTeamErrored"
										}
									}
								}
							}
						},
						playerSeasonPooPitchByTeam: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchPlayerSeasonPooPitchByTeam",
												cond: "shouldFetchPlayerSeasonPooPitchByTeam"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchPlayerSeasonPooPitchByTeam",
									entry: ["refreshPlayerSeasonPooPitchByTeamCancelSource"],
									invoke: {
										src: "fetchPlayerSeasonPooPitchByTeam",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchPlayerSeasonPooPitchByTeamSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchPlayerSeasonPooPitchByTeamErrored"
										}
									}
								}
							}
						}
					}
				}
			}
		},
		{
			guards: {
				shouldSetPlayerId: (
					context: TPitcherPitchOutcomesTableContext,
					event: TPitcherPitchOutcomesTableEvent
				) => event.type === SET_PLAYER_ID && context.playerId !== event.value,
				shouldFetchPlayerSeasonArsenalScoresByTeam: (
					context: TPitcherPitchOutcomesTableContext,
					_event: TPitcherPitchOutcomesTableEvent
				) =>
					context.playerSeasonArsenalScoresByTeam === undefined &&
					context.playerId !== undefined &&
					context.shouldFetchData === true,
				shouldFetchPlayerSeasonPopPitchByTeam: (
					context: TPitcherPitchOutcomesTableContext,
					_event: TPitcherPitchOutcomesTableEvent
				) =>
					context.playerSeasonPopPitchByTeam === undefined &&
					context.playerId !== undefined &&
					context.shouldFetchData === true,
				shouldFetchPlayerSeasonPooPitchByTeam: (
					context: TPitcherPitchOutcomesTableContext,
					_event: TPitcherPitchOutcomesTableEvent
				) =>
					context.playerSeasonPooPitchByTeam === undefined &&
					context.playerId !== undefined &&
					context.shouldFetchData === true
			},
			actions: {
				clearPlayerSeasonArsenalScoresByTeam: assign<
					TPitcherPitchOutcomesTableContext,
					TPitcherPitchOutcomesTableEvent
				>({
					playerSeasonArsenalScoresByTeam: (
						_context: TPitcherPitchOutcomesTableContext,
						_event: TPitcherPitchOutcomesTableEvent
					) => undefined,
					cancelSources: (
						context: TPitcherPitchOutcomesTableContext,
						_event: TPitcherPitchOutcomesTableEvent
					) => {
						const { cancelSources } = context;
						cancelSources[PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_CANCEL_SOURCE]?.cancel();
						cancelSources[PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				clearPlayerSeasonPopPitchByTeam: assign<
					TPitcherPitchOutcomesTableContext,
					TPitcherPitchOutcomesTableEvent
				>({
					playerSeasonPopPitchByTeam: (
						_context: TPitcherPitchOutcomesTableContext,
						_event: TPitcherPitchOutcomesTableEvent
					) => undefined,
					cancelSources: (
						context: TPitcherPitchOutcomesTableContext,
						_event: TPitcherPitchOutcomesTableEvent
					) => {
						const { cancelSources } = context;
						cancelSources[PLAYER_SEASON_POP_PITCH_BYTEAM_CANCEL_SOURCE]?.cancel();
						cancelSources[PLAYER_SEASON_POP_PITCH_BYTEAM_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				clearPlayerSeasonPooPitchByTeam: assign<
					TPitcherPitchOutcomesTableContext,
					TPitcherPitchOutcomesTableEvent
				>({
					playerSeasonPooPitchByTeam: (
						_context: TPitcherPitchOutcomesTableContext,
						_event: TPitcherPitchOutcomesTableEvent
					) => undefined,
					cancelSources: (
						context: TPitcherPitchOutcomesTableContext,
						_event: TPitcherPitchOutcomesTableEvent
					) => {
						const { cancelSources } = context;
						cancelSources[PLAYER_SEASON_POO_PITCH_BYTEAM_CANCEL_SOURCE]?.cancel();
						cancelSources[PLAYER_SEASON_POO_PITCH_BYTEAM_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				// Set Context Actions
				setPlayerSeasonArsenalScoresByTeam: assign<
					TPitcherPitchOutcomesTableContext,
					TPitcherPitchOutcomesTableEvent
				>({
					playerSeasonArsenalScoresByTeam: (
						context: TPitcherPitchOutcomesTableContext,
						event: TPitcherPitchOutcomesTableEvent
					) => {
						if (event.type !== SET_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM)
							return context.playerSeasonArsenalScoresByTeam;
						return event.value;
					},
					cancelSources: (
						context: TPitcherPitchOutcomesTableContext,
						event: TPitcherPitchOutcomesTableEvent
					) => {
						const { cancelSources } = context;
						if (event.type !== SET_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM) return cancelSources;
						cancelSources[PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_CANCEL_SOURCE]?.cancel();
						cancelSources[PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				setPlayerId: assign<TPitcherPitchOutcomesTableContext, TPitcherPitchOutcomesTableEvent>({
					lastPlayerId: (
						context: TPitcherPitchOutcomesTableContext,
						event: TPitcherPitchOutcomesTableEvent
					) => {
						if (event.type !== SET_PLAYER_ID) return context.lastPlayerId;
						return context.playerId;
					},
					playerId: (context: TPitcherPitchOutcomesTableContext, event: TPitcherPitchOutcomesTableEvent) => {
						if (event.type !== SET_PLAYER_ID) return context.playerId;
						return event.value;
					},
					cancelSources: (
						context: TPitcherPitchOutcomesTableContext,
						_event: TPitcherPitchOutcomesTableEvent
					) => {
						Object.entries(context.cancelSources).forEach((cancelSource: [string, CancelTokenSource]) =>
							cancelSource[1]?.cancel()
						);
						return {};
					}
				}),
				setPlayerSeasonPopPitchByTeam: assign<
					TPitcherPitchOutcomesTableContext,
					TPitcherPitchOutcomesTableEvent
				>({
					playerSeasonPopPitchByTeam: (
						context: TPitcherPitchOutcomesTableContext,
						event: TPitcherPitchOutcomesTableEvent
					) => {
						if (event.type !== SET_PLAYER_SEASON_POP_PITCH_BYTEAM)
							return context.playerSeasonPopPitchByTeam;
						return event.value;
					},
					cancelSources: (
						context: TPitcherPitchOutcomesTableContext,
						event: TPitcherPitchOutcomesTableEvent
					) => {
						const { cancelSources } = context;
						if (event.type !== SET_PLAYER_SEASON_POP_PITCH_BYTEAM) return cancelSources;
						cancelSources[PLAYER_SEASON_POP_PITCH_BYTEAM_CANCEL_SOURCE]?.cancel();
						cancelSources[PLAYER_SEASON_POP_PITCH_BYTEAM_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				setPlayerSeasonPooPitchByTeam: assign<
					TPitcherPitchOutcomesTableContext,
					TPitcherPitchOutcomesTableEvent
				>({
					playerSeasonPooPitchByTeam: (
						context: TPitcherPitchOutcomesTableContext,
						event: TPitcherPitchOutcomesTableEvent
					) => {
						if (event.type !== SET_PLAYER_SEASON_POO_PITCH_BYTEAM)
							return context.playerSeasonPooPitchByTeam;
						return event.value;
					},
					cancelSources: (
						context: TPitcherPitchOutcomesTableContext,
						event: TPitcherPitchOutcomesTableEvent
					) => {
						const { cancelSources } = context;
						if (event.type !== SET_PLAYER_SEASON_POO_PITCH_BYTEAM) return cancelSources;
						cancelSources[PLAYER_SEASON_POO_PITCH_BYTEAM_CANCEL_SOURCE]?.cancel();
						cancelSources[PLAYER_SEASON_POO_PITCH_BYTEAM_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				setFilters: assign<TPitcherPitchOutcomesTableContext, TPitcherPitchOutcomesTableEvent>({
					filters: (context: TPitcherPitchOutcomesTableContext, event: TPitcherPitchOutcomesTableEvent) => {
						if (event.type !== SET_FILTERS) return context.filters;
						return event.value;
					}
				}),
				// Cancel Source Actions
				refreshPlayerSeasonArsenalScoresByTeamCancelSource: assign<
					TPitcherPitchOutcomesTableContext,
					TPitcherPitchOutcomesTableEvent
				>({
					cancelSources: (
						context: TPitcherPitchOutcomesTableContext,
						_event: TPitcherPitchOutcomesTableEvent
					) => {
						if (context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_CANCEL_SOURCE].cancel();
						context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshPlayerSeasonPopPitchByTeamCancelSource: assign<
					TPitcherPitchOutcomesTableContext,
					TPitcherPitchOutcomesTableEvent
				>({
					cancelSources: (
						context: TPitcherPitchOutcomesTableContext,
						_event: TPitcherPitchOutcomesTableEvent
					) => {
						if (context.cancelSources[PLAYER_SEASON_POP_PITCH_BYTEAM_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_SEASON_POP_PITCH_BYTEAM_CANCEL_SOURCE].cancel();
						context.cancelSources[PLAYER_SEASON_POP_PITCH_BYTEAM_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshPlayerSeasonPooPitchByTeamCancelSource: assign<
					TPitcherPitchOutcomesTableContext,
					TPitcherPitchOutcomesTableEvent
				>({
					cancelSources: (
						context: TPitcherPitchOutcomesTableContext,
						_event: TPitcherPitchOutcomesTableEvent
					) => {
						if (context.cancelSources[PLAYER_SEASON_POO_PITCH_BYTEAM_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_SEASON_POO_PITCH_BYTEAM_CANCEL_SOURCE].cancel();
						context.cancelSources[PLAYER_SEASON_POO_PITCH_BYTEAM_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				// Fetch Success Actions
				handleFetchPlayerSeasonArsenalScoresByTeamSuccess: assign<
					TPitcherPitchOutcomesTableContext,
					TPitcherPitchOutcomesTableEvent
				>({
					playerSeasonArsenalScoresByTeam: (
						context: TPitcherPitchOutcomesTableContext,
						event: TPitcherPitchOutcomesTableEvent
					) => {
						if (event.type !== FETCH_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_DONE)
							return context.playerSeasonArsenalScoresByTeam;
						return event.data;
					}
				}),
				handleFetchPlayerSeasonPopPitchByTeamSuccess: assign<
					TPitcherPitchOutcomesTableContext,
					TPitcherPitchOutcomesTableEvent
				>({
					playerSeasonPopPitchByTeam: (
						context: TPitcherPitchOutcomesTableContext,
						event: TPitcherPitchOutcomesTableEvent
					) => {
						if (event.type !== FETCH_PLAYER_SEASON_POP_PITCH_BYTEAM_DONE)
							return context.playerSeasonPopPitchByTeam;
						return event.data;
					}
				}),
				handleFetchPlayerSeasonPooPitchByTeamSuccess: assign<
					TPitcherPitchOutcomesTableContext,
					TPitcherPitchOutcomesTableEvent
				>({
					playerSeasonPooPitchByTeam: (
						context: TPitcherPitchOutcomesTableContext,
						event: TPitcherPitchOutcomesTableEvent
					) => {
						if (event.type !== FETCH_PLAYER_SEASON_POO_PITCH_BYTEAM_DONE)
							return context.playerSeasonPooPitchByTeam;
						return event.data;
					}
				}),
				// Fetch Error Actions
				handleFetchPlayerSeasonArsenalScoresByTeamErrored: (
					context: TPitcherPitchOutcomesTableContext,
					event: TPitcherPitchOutcomesTableEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_ERROR ? event.data : undefined,
						context.toast,
						"Pitch Outcomes - Arsenal Scores by Team",
						"Error fetching arsenal scores by player, season, and team"
					);
				},
				handleFetchPlayerSeasonPopPitchByTeamErrored: (
					context: TPitcherPitchOutcomesTableContext,
					event: TPitcherPitchOutcomesTableEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_PLAYER_SEASON_POP_PITCH_BYTEAM_ERROR ? event.data : undefined,
						context.toast,
						"Pitch Outcomes - PoP Pitch by Team",
						"Error fetching PoP Pitch by player, season, and team"
					);
				},
				handleFetchPlayerSeasonPooPitchByTeamErrored: (
					context: TPitcherPitchOutcomesTableContext,
					event: TPitcherPitchOutcomesTableEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_PLAYER_SEASON_POO_PITCH_BYTEAM_ERROR ? event.data : undefined,
						context.toast,
						"Pitch Outcomes - PoO Pitch by Team",
						"Error fetching PoO Pitch by player, season, and team"
					);
				}
			},
			services: {
				fetchPlayerSeasonArsenalScoresByTeam: (
					context: TPitcherPitchOutcomesTableContext,
					_event: AnyEventObject
				) => {
					const { playerId } = context;
					if (!playerId) return Promise.resolve(null);
					const fetchFunc = () =>
						fetchPlayerSeasonArsenalScoresByTeam(
							{
								playerId: playerId,
								playingLevel: PLAYING_LEVEL_AMA,
								countSplit: COUNT_SPLIT_OVERALL,
								isUseCache: true
							},
							context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchPlayerSeasonPopPitchByTeam: (
					context: TPitcherPitchOutcomesTableContext,
					_event: AnyEventObject
				) => {
					const { playerId } = context;
					if (!playerId) return Promise.resolve(null);
					const fetchFunc = () =>
						fetchPitchOutcomeProbabilitiesPitchByTeam(
							{
								playerId,
								countSplit: PITCH_GROUP_OVERALL,
								isUseCache: true,
								playingLevel: PLAYING_LEVEL_AMA
							},
							context.cancelSources[PLAYER_SEASON_POP_PITCH_BYTEAM_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchPlayerSeasonPooPitchByTeam: (
					context: TPitcherPitchOutcomesTableContext,
					_event: AnyEventObject
				) => {
					const { playerId } = context;
					if (!playerId) return Promise.resolve(null);
					const fetchFunc = () =>
						fetchPitchOutcomeObservedPitchByTeam(
							{
								playerId,
								countSplit: PITCH_GROUP_OVERALL,
								isUseCache: true,
								schema: SCHEMA_TYPE_EXPANDED,
								playingLevel: PLAYING_LEVEL_AMA
							},
							context.cancelSources[PLAYER_SEASON_POO_PITCH_BYTEAM_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				}
			}
		}
	);

export default PitcherPitchOutcomesTableMachine;
