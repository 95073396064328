import React from "react";
import { VStack } from "@chakra-ui/react";
import AmaPitcherFoundationalSkillsTable from "_react/shared/ui/data/tables/AmaPitcherFoundationalSkillsTable/AmaPitcherFoundationalSkillsTable";
import AmaPitcherPitchCharacteristicsTable from "_react/shared/ui/data/tables/AmaPitcherPitchCharacteristicsTable/AmaPitcherPitchCharacteristicsTable";
import AmaPitcherPitchOutcomesTable from "_react/shared/ui/data/tables/AmaPitcherPitchOutcomesTable/AmaPitcherPitchOutcomesTable";

type TPitcherStatisticsTablesProps = {
	playerId: number;
};

const PitcherStatisticsTables = ({ playerId }: TPitcherStatisticsTablesProps) => {
	return (
		<VStack marginBottom="10" gap="10">
			<AmaPitcherFoundationalSkillsTable
				title="Foundational Skills"
				playerId={playerId}
				style={{
					container: { width: "100%" },
					tableContainer: { width: "100%", overflowX: "scroll" }
				}}
			/>
			<AmaPitcherPitchCharacteristicsTable
				title="Pitch Characteristics"
				playerId={playerId}
				style={{
					container: { width: "100%" },
					tableContainer: { width: "100%", overflowX: "scroll" }
				}}
			/>
			<AmaPitcherPitchOutcomesTable
				title="Pitch Outcomes"
				playerId={playerId}
				style={{
					container: { width: "100%" },
					tableContainer: { width: "100%", overflowX: "scroll" }
				}}
			/>
		</VStack>
	);
};

export default PitcherStatisticsTables;
