import { TAmaPositionPlayerFoundationalSkillsRow } from "_react/shared/ui/data/tables/AmaPositionPlayerFoundationalSkillsTable/_types";

// Used to get the level to display for a row
export const getLevelsFromRow = (row: TAmaPositionPlayerFoundationalSkillsRow): Array<string> => {
	// Child Rows or rows with no nested data
	if ("team" in row.combinedPlayerSeasonHitterGradesData)
		return row.combinedPlayerSeasonHitterGradesData.team?.level
			? [row.combinedPlayerSeasonHitterGradesData.team?.level]
			: [];
	// Parent Rows with nested data
	if (row.childData && row.childData.length > 1) {
		return [
			...new Set(
				row.childData.reduce((acc: Array<string>, childRow: TAmaPositionPlayerFoundationalSkillsRow) => {
					if (
						"team" in childRow.combinedPlayerSeasonHitterGradesData &&
						childRow.combinedPlayerSeasonHitterGradesData.team?.level
					)
						acc.push(childRow.combinedPlayerSeasonHitterGradesData.team?.level);
					return acc;
				}, [])
			)
		];
	}
	return [];
};
