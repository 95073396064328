import { Machine, assign, Interpreter, AnyEventObject } from "xstate";
import { AxiosError, CancelTokenSource } from "axios";
import { CreateToastFnReturn } from "@chakra-ui/react";

import { promiseWRetry } from "utils/helpers";
import { getCancelSource } from "utils/url_helpers";
import { displayAxiosErrorToast } from "_react/shared/_helpers/axios";
import { IPlayerSeasonArsenalScoresByTeamSchema } from "_react/shared/data_models/arsenal_scores/_types";
import {
	BATS_L,
	BATS_R,
	COUNT_SPLIT_OVERALL,
	PLAYING_LEVEL_AMA,
	SCHEMA_TYPE_EXPANDED,
	GAME_TYPE_OVERALL
} from "_react/shared/data_models/arsenal_scores/_constants";
import { fetchPlayerSeasonArsenalScoresByTeam } from "_react/shared/data_models/arsenal_scores/_network";

import { STUFF } from "_react/shared/ui/data/tables/AmaPitcherFoundationalSkillsTable/_constants";
import {
	TAmaPitcherFoundationalSkillsTableData,
	TStuffOrShapeValue
} from "_react/shared/ui/data/tables/AmaPitcherFoundationalSkillsTable/_types";

export const PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_CANCEL_SOURCE = "playerSeasonArsenalScoresByTeam";

export type TAmaPitcherFoundationalSkillsTableCancelSource = {
	[PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_CANCEL_SOURCE]?: CancelTokenSource;
};

export type TAmaPitcherFoundationalSkillsTableFilters = {
	bats: Array<string>;
	pitchTypes?: Array<string>;
	levels?: Array<string>;
	maxSeason?: number;
	minSeason?: number;
};

export type TAmaPitcherFoundationalSkillsTableContext = {
	playerId?: number;
	lastPlayerId?: number;
	shouldFetchData?: boolean;
	filters: TAmaPitcherFoundationalSkillsTableFilters;
	playerSeasonArsenalScoresByTeam?: Array<IPlayerSeasonArsenalScoresByTeamSchema> | null;
	displayStuffOrShape?: TStuffOrShapeValue;
	cancelSources: TAmaPitcherFoundationalSkillsTableCancelSource;
	toast?: CreateToastFnReturn;
};

interface IAmaPitcherFoundationalSkillsTableStateSchema {
	states: {
		initializing: {};
		initialized: {
			states: {
				// Fetches all arsenal scores by player season team
				playerSeasonArsenalScoresByTeam: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
			};
		};
	};
}

export const FETCHING_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM = {
	initialized: { playerSeasonArsenalScoresByTeam: "fetching" }
};

const FETCH_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_DONE = "done.invoke.fetchPlayerSeasonArsenalScoresByTeam:invocation[0]";
const FETCH_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_ERROR =
	"error.platform.fetchPlayerSeasonArsenalScoresByTeam:invocation[0]";
export const SET_PLAYER_ID = "SET_PLAYER_ID";
export const SET_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM = "SET_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM";
export const SET_FILTERS = "SET_FILTERS";
export const SET_DISPLAY_STUFF_OR_SHAPE = "SET_DISPLAY_STUFF_OR_SHAPE";

type TFetchPlayerSeasonArsenalScoresByTeamEvent = {
	type: typeof FETCH_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_DONE;
	data: Array<IPlayerSeasonArsenalScoresByTeamSchema> | undefined;
};
type TFetchPlayerSeasonArsenalScoresByTeamErrorEvent = {
	type: typeof FETCH_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_ERROR;
	data?: AxiosError | Error;
};

type TSetPlayerIdEvent = { type: typeof SET_PLAYER_ID; value: number | undefined };
type TSetPlayerSeasonArsenalScoresByTeamEvent = {
	type: typeof SET_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM;
	value?: Array<IPlayerSeasonArsenalScoresByTeamSchema>;
};
type TSetFiltersEvent = {
	type: typeof SET_FILTERS;
	value: TAmaPitcherFoundationalSkillsTableFilters;
};

type TSetDisplayStuffOrShapeEvent = {
	type: typeof SET_DISPLAY_STUFF_OR_SHAPE;
	value: TStuffOrShapeValue;
};

type TAmaPitcherFoundationalSkillsTableEvent =
	| TFetchPlayerSeasonArsenalScoresByTeamEvent
	| TFetchPlayerSeasonArsenalScoresByTeamErrorEvent
	| TSetPlayerIdEvent
	| TSetPlayerSeasonArsenalScoresByTeamEvent
	| TSetFiltersEvent
	| TSetDisplayStuffOrShapeEvent;

export type TAmaPitcherFoundationalSkillsTableSend = Interpreter<
	TAmaPitcherFoundationalSkillsTableContext,
	IAmaPitcherFoundationalSkillsTableStateSchema,
	TAmaPitcherFoundationalSkillsTableEvent
>["send"];

const AmaPitcherFoundationalSkillsTableMachine = (
	playerIdProp?: number,
	data?: TAmaPitcherFoundationalSkillsTableData,
	shouldFetchDataProp = true,
	toastProp?: CreateToastFnReturn
) =>
	Machine<
		TAmaPitcherFoundationalSkillsTableContext,
		IAmaPitcherFoundationalSkillsTableStateSchema,
		TAmaPitcherFoundationalSkillsTableEvent
	>(
		{
			id: "projectionsTable",
			initial: "initializing",
			context: {
				playerId: playerIdProp,
				lastPlayerId: undefined,
				shouldFetchData: shouldFetchDataProp,
				filters: {
					bats: [BATS_L, BATS_R],
					pitchTypes: undefined,
					levels: undefined
				},
				playerSeasonArsenalScoresByTeam: data?.playerSeasonArsenalScoresByTeam,
				displayStuffOrShape: STUFF,
				cancelSources: {},
				toast: toastProp
			},
			states: {
				initializing: {
					always: "initialized"
				},
				initialized: {
					type: "parallel",
					on: {
						SET_PLAYER_ID: {
							actions: ["setPlayerId", "clearPlayerSeasonArsenalScoresByTeam"],
							cond: "shouldSetPlayerId"
						},
						SET_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM: { actions: "setPlayerSeasonArsenalScoresByTeam" },
						SET_FILTERS: { actions: "setFilters" },
						SET_DISPLAY_STUFF_OR_SHAPE: { actions: "setDisplayStuffOrShape" }
					},
					states: {
						playerSeasonArsenalScoresByTeam: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchPlayerSeasonArsenalScoresByTeam",
												cond: "shouldFetchPlayerSeasonArsenalScoresByTeam"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchPlayerSeasonArsenalScoresByTeam",
									entry: ["refreshPlayerSeasonArsenalScoresByTeamCancelSource"],
									invoke: {
										src: "fetchPlayerSeasonArsenalScoresByTeam",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchPlayerSeasonArsenalScoresByTeamSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchPlayerSeasonArsenalScoresByTeamErrored"
										}
									}
								}
							}
						}
					}
				}
			}
		},
		{
			guards: {
				shouldSetPlayerId: (
					context: TAmaPitcherFoundationalSkillsTableContext,
					event: TAmaPitcherFoundationalSkillsTableEvent
				) => event.type === SET_PLAYER_ID && context.playerId !== event.value,
				shouldFetchPlayerSeasonArsenalScoresByTeam: (
					context: TAmaPitcherFoundationalSkillsTableContext,
					_event: TAmaPitcherFoundationalSkillsTableEvent
				) =>
					context.playerSeasonArsenalScoresByTeam === undefined &&
					context.playerId !== undefined &&
					context.shouldFetchData === true
			},
			actions: {
				clearPlayerSeasonArsenalScoresByTeam: assign<
					TAmaPitcherFoundationalSkillsTableContext,
					TAmaPitcherFoundationalSkillsTableEvent
				>({
					playerSeasonArsenalScoresByTeam: (
						_context: TAmaPitcherFoundationalSkillsTableContext,
						_event: TAmaPitcherFoundationalSkillsTableEvent
					) => undefined,
					cancelSources: (
						context: TAmaPitcherFoundationalSkillsTableContext,
						_event: TAmaPitcherFoundationalSkillsTableEvent
					) => {
						const { cancelSources } = context;
						cancelSources[PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_CANCEL_SOURCE]?.cancel();
						cancelSources[PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				// Set Context Actions
				setPlayerId: assign<TAmaPitcherFoundationalSkillsTableContext, TAmaPitcherFoundationalSkillsTableEvent>(
					{
						playerId: (
							context: TAmaPitcherFoundationalSkillsTableContext,
							event: TAmaPitcherFoundationalSkillsTableEvent
						) => {
							if (event.type !== SET_PLAYER_ID) return context.playerId;
							return event.value;
						},
						cancelSources: (
							context: TAmaPitcherFoundationalSkillsTableContext,
							_event: TAmaPitcherFoundationalSkillsTableEvent
						) => {
							Object.entries(context.cancelSources).forEach((cancelSource: [string, CancelTokenSource]) =>
								cancelSource[1]?.cancel()
							);
							return {};
						}
					}
				),
				setPlayerSeasonArsenalScoresByTeam: assign<
					TAmaPitcherFoundationalSkillsTableContext,
					TAmaPitcherFoundationalSkillsTableEvent
				>({
					playerSeasonArsenalScoresByTeam: (
						context: TAmaPitcherFoundationalSkillsTableContext,
						event: TAmaPitcherFoundationalSkillsTableEvent
					) => {
						if (event.type !== SET_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM)
							return context.playerSeasonArsenalScoresByTeam;
						return event.value;
					},
					cancelSources: (
						context: TAmaPitcherFoundationalSkillsTableContext,
						event: TAmaPitcherFoundationalSkillsTableEvent
					) => {
						const { cancelSources } = context;
						if (event.type !== SET_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM) return cancelSources;
						cancelSources[PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_CANCEL_SOURCE]?.cancel();
						cancelSources[PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				setFilters: assign<TAmaPitcherFoundationalSkillsTableContext, TAmaPitcherFoundationalSkillsTableEvent>({
					filters: (
						context: TAmaPitcherFoundationalSkillsTableContext,
						event: TAmaPitcherFoundationalSkillsTableEvent
					) => {
						if (event.type !== SET_FILTERS) return context.filters;
						return event.value;
					}
				}),
				setDisplayStuffOrShape: assign<
					TAmaPitcherFoundationalSkillsTableContext,
					TAmaPitcherFoundationalSkillsTableEvent
				>({
					displayStuffOrShape: (
						context: TAmaPitcherFoundationalSkillsTableContext,
						event: TAmaPitcherFoundationalSkillsTableEvent
					) => {
						if (event.type !== SET_DISPLAY_STUFF_OR_SHAPE) return context.displayStuffOrShape;
						return event.value;
					}
				}),
				// Cancel Source Actions
				refreshPlayerSeasonArsenalScoresByTeamCancelSource: assign<
					TAmaPitcherFoundationalSkillsTableContext,
					TAmaPitcherFoundationalSkillsTableEvent
				>({
					cancelSources: (
						context: TAmaPitcherFoundationalSkillsTableContext,
						_event: TAmaPitcherFoundationalSkillsTableEvent
					) => {
						if (context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_CANCEL_SOURCE].cancel();
						context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				// Fetch Success Actions
				handleFetchPlayerSeasonArsenalScoresByTeamSuccess: assign<
					TAmaPitcherFoundationalSkillsTableContext,
					TAmaPitcherFoundationalSkillsTableEvent
				>({
					playerSeasonArsenalScoresByTeam: (
						context: TAmaPitcherFoundationalSkillsTableContext,
						event: TAmaPitcherFoundationalSkillsTableEvent
					) => {
						if (event.type !== FETCH_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_DONE)
							return context.playerSeasonArsenalScoresByTeam;
						return event.data;
					}
				}),
				// Fetch Error Actions
				handleFetchPlayerSeasonArsenalScoresByTeamErrored: (
					context: TAmaPitcherFoundationalSkillsTableContext,
					event: TAmaPitcherFoundationalSkillsTableEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_ERROR ? event.data : undefined,
						context.toast,
						"Foundational Skills - Arsenal Scores by Team",
						"Error fetching arsenal scores by player, season, and team"
					);
				}
			},
			services: {
				fetchPlayerSeasonArsenalScoresByTeam: (
					context: TAmaPitcherFoundationalSkillsTableContext,
					_event: AnyEventObject
				) => {
					const { playerId } = context;
					if (!playerId) return Promise.resolve(null);
					const fetchFunc = () =>
						fetchPlayerSeasonArsenalScoresByTeam(
							{
								playerId: playerId,
								playingLevel: PLAYING_LEVEL_AMA,
								countSplit: COUNT_SPLIT_OVERALL,
								gameType: GAME_TYPE_OVERALL,
								schema: SCHEMA_TYPE_EXPANDED,
								isUseCache: true
							},
							context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				}
			}
		}
	);

export default AmaPitcherFoundationalSkillsTableMachine;
