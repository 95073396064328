import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
	Box,
	ButtonGroup,
	Flex,
	HStack,
	IconButton,
	Menu,
	MenuButton,
	MenuDivider,
	MenuItemOption,
	MenuList,
	MenuOptionGroup,
	Portal,
	RangeSlider,
	RangeSliderFilledTrack,
	RangeSliderThumb,
	RangeSliderTrack,
	Spacer,
	Text,
	Tooltip,
	useToast,
	VStack
} from "@chakra-ui/react";
import { SystemStyleObject } from "@chakra-ui/styled-system";
import { useMachine } from "@xstate/react";

import {
	getAmaLevelDisplayText,
	getMinAndMaxSeason,
	getSeasonFilters,
	updateFilters
} from "_react/shared/_helpers/stats";
import { THROWS_L, THROWS_OVERALL, THROWS_R } from "_react/shared/data_models/hitter_grades/_constants";
import {
	IPlayerSeasonHitterGrades,
	IPlayerSeasonHitterGradesByTeamApiResponse,
	IPlayerSeasonHitterGradesByTeamLkLevel
} from "_react/shared/data_models/hitter_grades/_types";
import { VALID_AMA_LEVELS } from "_react/shared/data_models/team/_constants";
import CloseIcon from "_react/shared/legacy/ui/icons/Clear";
import { DEFAULT_STATS_TABLE_FILTERS, isDefaultFilters } from "_react/shared/ui/data/tables/shared/Filters";
import FilterAlt from "_react/shared/ui/icons/FilterAlt";
import OutlineInfo from "_react/shared/ui/icons/OutlineInfo";
import DataSourceBadges, {
	DATA_SOURCE_HAWKEYE,
	DATA_SOURCE_TRACKMAN
} from "_react/shared/ui/presentation/components/DataSourceBadges/DataSourceBadges";
import { DESC } from "_react/shared/ui/presentation/components/Table/_constants";
import { TTableProps, TColumn } from "_react/shared/ui/presentation/components/Table/_types";
import Table from "_react/shared/ui/presentation/components/Table/Table";
import TeamLevelBadge from "_react/shared/ui/presentation/components/TeamLevelBadge/TeamLevelBadge";

import {
	getAmaPositionPlayerFoundationalSkillsColumns,
	NUM_DISPLAY_SEASONS
} from "_react/shared/ui/data/tables/AmaPositionPlayerFoundationalSkillsTable/_constants";
import { getLevelsFromRow } from "_react/shared/ui/data/tables/AmaPositionPlayerFoundationalSkillsTable/_helpers";
import AmaPositionPlayerFoundationalSkillsTableMachine, {
	FETCHING_PLAYER_SEASON_HITTER_GRADES,
	FETCHING_PLAYER_SEASON_HITTER_GRADES_BYTEAM,
	SET_FILTERS,
	SET_PLAYER_ID,
	SET_PLAYER_SEASON_HITTER_GRADES,
	SET_PLAYER_SEASON_HITTER_GRADES_BYTEAM,
	TAmaPositionPlayerFoundationalSkillsTableContext
} from "_react/shared/ui/data/tables/AmaPositionPlayerFoundationalSkillsTable/_machine";
import {
	ICombinedPlayerSeasonHitterGradesSchema,
	TAmaPositionPlayerFoundationalSkillsRow,
	TAmaPositionPlayerFoundationalSkillsTableData
} from "_react/shared/ui/data/tables/AmaPositionPlayerFoundationalSkillsTable/_types";

type TAmaPositionPlayerFoundationalSkillsTableStyle = {
	container?: SystemStyleObject;
	tableContainer?: SystemStyleObject;
};

type TAmaPositionPlayerFoundationalSkillsTableProps = {
	title?: string;
	playerId?: number;
	data?: TAmaPositionPlayerFoundationalSkillsTableData;
	columns?: Array<string>;
	shouldFetchData?: boolean;
	isShowFilters?: boolean;
	tableProps?: TTableProps<TAmaPositionPlayerFoundationalSkillsRow, keyof TAmaPositionPlayerFoundationalSkillsRow>;
	style?: TAmaPositionPlayerFoundationalSkillsTableStyle;
};

const AmaPositionPlayerFoundationalSkillsTable = ({
	title,
	playerId: playerIdProp,
	data,
	columns,
	shouldFetchData = true,
	isShowFilters = true,
	tableProps,
	style
}: TAmaPositionPlayerFoundationalSkillsTableProps) => {
	const toast = useToast();
	const [showSeasonRangeTooltip, setShowSeasonRangeTooltip] = useState(false);

	const [current, send] = useMachine(
		AmaPositionPlayerFoundationalSkillsTableMachine(playerIdProp, data, shouldFetchData, toast)
	);
	const isFetchingPlayerSeasonHitterGrades = current.matches(FETCHING_PLAYER_SEASON_HITTER_GRADES);
	const isFetchingPlayerSeasonHitterGradesByTeam = current.matches(FETCHING_PLAYER_SEASON_HITTER_GRADES_BYTEAM);
	const isLoading = isFetchingPlayerSeasonHitterGrades || isFetchingPlayerSeasonHitterGradesByTeam;

	const context = current.context as TAmaPositionPlayerFoundationalSkillsTableContext;
	const { playerId, filters, playerSeasonHitterGrades, playerSeasonHitterGradesByTeam } = context;

	// Update machine context when props change
	useEffect(() => {
		if (playerIdProp !== playerId) send({ type: SET_PLAYER_ID, value: playerIdProp });
	}, [send, playerIdProp, playerId, shouldFetchData]);

	useEffect(() => {
		if (data?.playerSeasonHitterGrades !== playerSeasonHitterGrades && shouldFetchData === false)
			send({ type: SET_PLAYER_SEASON_HITTER_GRADES, value: data?.playerSeasonHitterGrades });
	}, [send, data?.playerSeasonHitterGrades, playerSeasonHitterGrades, shouldFetchData]);

	useEffect(() => {
		if (data?.playerSeasonHitterGradesByTeam !== playerSeasonHitterGradesByTeam && shouldFetchData === false)
			send({ type: SET_PLAYER_SEASON_HITTER_GRADES_BYTEAM, value: data?.playerSeasonHitterGradesByTeam });
	}, [send, data?.playerSeasonHitterGradesByTeam, playerSeasonHitterGradesByTeam, shouldFetchData]);

	// Get max and min season
	const [minSeason, maxSeason] = useMemo(
		() => getMinAndMaxSeason<IPlayerSeasonHitterGrades>(playerSeasonHitterGrades ?? []),
		[playerSeasonHitterGrades]
	);

	//
	// Season filter options
	//
	const seasonFilters: { minSeason: number; maxSeason: number } = useMemo(
		() => getSeasonFilters(filters.minSeason, filters.maxSeason, maxSeason, NUM_DISPLAY_SEASONS),
		[filters.minSeason, filters.maxSeason, maxSeason]
	);

	//
	// Level filter options
	//

	// Compute the level filter options
	const levelFilterOptions: Array<IPlayerSeasonHitterGradesByTeamLkLevel> = useMemo(
		() =>
			playerSeasonHitterGradesByTeam
				?.reduce(
					(
						acc: Array<IPlayerSeasonHitterGradesByTeamLkLevel>,
						curr: IPlayerSeasonHitterGradesByTeamApiResponse
					) => {
						const levelRel: IPlayerSeasonHitterGradesByTeamLkLevel | undefined = curr.team?.levelRel;
						if (
							levelRel &&
							levelRel.value &&
							!acc.some(val => val.value === levelRel.value)
							// && VALID_AMA_LEVELS.includes(levelRel.value)
						)
							acc.push(levelRel);
						return acc;
					},
					[]
				)
				?.sort(
					(a: IPlayerSeasonHitterGradesByTeamLkLevel, b: IPlayerSeasonHitterGradesByTeamLkLevel) =>
						(a.sortOrder ?? Number.MAX_SAFE_INTEGER) - (b.sortOrder ?? Number.MAX_SAFE_INTEGER)
				) ?? [],
		[playerSeasonHitterGradesByTeam]
	);

	// Once the level filter options are computed for the first time, send them to the machine
	useEffect(() => {
		if (filters.levels === undefined && levelFilterOptions.length > 0) {
			const newFilters = {
				...filters,
				levels: levelFilterOptions.map((option: IPlayerSeasonHitterGradesByTeamLkLevel) => option.value)
			};
			send({ type: SET_FILTERS, value: newFilters });
		}
	}, [filters, send, levelFilterOptions]);

	//
	// Filter data for table
	//
	const throwsFilter: string = useMemo(() => (filters.throws.length === 2 ? THROWS_OVERALL : filters.throws?.[0]), [
		filters.throws
	]);

	const filteredPlayerSeasonHitterGradesData: Array<IPlayerSeasonHitterGrades> | undefined | null = useMemo(() => {
		if (isLoading) return undefined;
		if (!playerSeasonHitterGrades) return playerSeasonHitterGrades;
		return playerSeasonHitterGrades.filter(
			(grades: IPlayerSeasonHitterGrades) =>
				grades.season <= seasonFilters.maxSeason &&
				grades.season >= seasonFilters.minSeason &&
				grades.throws === throwsFilter
		);
	}, [isLoading, seasonFilters, throwsFilter, playerSeasonHitterGrades]);

	// Check for default filters
	const defaultFiltersSet: boolean = useMemo(() => {
		const availableLevels = levelFilterOptions.map(
			(option: IPlayerSeasonHitterGradesByTeamLkLevel) => option.value
		);

		return isDefaultFilters(filters, undefined, availableLevels, maxSeason, NUM_DISPLAY_SEASONS);
	}, [filters, levelFilterOptions, maxSeason]);

	const resetFilters = useCallback(() => {
		send({ type: SET_FILTERS, value: DEFAULT_STATS_TABLE_FILTERS });
	}, [send]);

	//
	// Combine data for table
	//
	const combinedTableData: Array<TAmaPositionPlayerFoundationalSkillsRow> | undefined = useMemo(() => {
		if (!filteredPlayerSeasonHitterGradesData) return undefined;
		const combinedData: Array<TAmaPositionPlayerFoundationalSkillsRow> = [];
		const uniqueSeasons = [
			...new Set(filteredPlayerSeasonHitterGradesData.map((grades: IPlayerSeasonHitterGrades) => grades.season))
		];
		uniqueSeasons.forEach((season: number) => {
			const grades = filteredPlayerSeasonHitterGradesData?.filter(
				(grades: IPlayerSeasonHitterGrades) => grades.season === season
			);
			const gradesByTeam = playerSeasonHitterGradesByTeam?.filter(
				(gradesByTeam: IPlayerSeasonHitterGradesByTeamApiResponse) =>
					gradesByTeam.season === season && gradesByTeam.throws === throwsFilter
			);
			// If we found a row matching the season, use those stats.
			// We only expect to have one row per season.
			if (grades?.length === 1) {
				let combinedPlayerSeasonHitterGradesData: ICombinedPlayerSeasonHitterGradesSchema = grades[0];
				let combinedChildData = gradesByTeam?.map((grades: IPlayerSeasonHitterGradesByTeamApiResponse) => {
					return { combinedPlayerSeasonHitterGradesData: grades };
				});
				// If there is only one child row, then we want to display it as the overall row
				if (gradesByTeam && gradesByTeam.length === 1) {
					combinedPlayerSeasonHitterGradesData = {
						...grades[0],
						teamId: gradesByTeam[0].teamId,
						team: gradesByTeam[0].team
					};
					combinedChildData = undefined;
				}
				combinedData.push({
					combinedPlayerSeasonHitterGradesData: combinedPlayerSeasonHitterGradesData,
					childData: combinedChildData
				});
			}
		});
		// Apply level filters
		return combinedData.reduce(
			(acc: TAmaPositionPlayerFoundationalSkillsRow[], row: TAmaPositionPlayerFoundationalSkillsRow) => {
				const uniqueLevels: Array<string> = getLevelsFromRow(row);
				const shouldDisplayEntireRow = uniqueLevels.every(level => filters.levels?.includes(level));

				// If all of the levels associated with the row meet the level filters, push the entire row and return early
				if (shouldDisplayEntireRow) {
					acc.push(row);
					return acc;
				}

				// Otherwise, filter the child data and turn it into multiple parent rows with no child data.
				const filteredChildData = row.childData?.filter(
					(c: TAmaPositionPlayerFoundationalSkillsRow) =>
						"team" in c.combinedPlayerSeasonHitterGradesData &&
						filters.levels?.includes(c.combinedPlayerSeasonHitterGradesData?.team?.level ?? "")
				);
				filteredChildData?.forEach((datum: TAmaPositionPlayerFoundationalSkillsRow) => {
					acc.push({
						combinedPlayerSeasonHitterGradesData: datum.combinedPlayerSeasonHitterGradesData,
						childData: undefined
					});
				});
				return acc;
			},
			[]
		);
	}, [filters.levels, throwsFilter, filteredPlayerSeasonHitterGradesData, playerSeasonHitterGradesByTeam]);

	const posSkillsColumns = useMemo(() => {
		return getAmaPositionPlayerFoundationalSkillsColumns(throwsFilter);
	}, [throwsFilter]);

	// Filter columns based on prop
	const filteredColumns = useMemo(() => {
		if (!columns) return posSkillsColumns;
		return posSkillsColumns.filter(
			(col: TColumn<TAmaPositionPlayerFoundationalSkillsRow, keyof TAmaPositionPlayerFoundationalSkillsRow>) =>
				columns.includes(col.value)
		);
	}, [columns, posSkillsColumns]);

	// Filtering
	const handleThrowsSelect = (value: string) => {
		const newFilters = {
			...filters,
			throws: updateFilters(filters.throws, value)
		};
		send({ type: SET_FILTERS, value: newFilters });
	};

	const handleLevelSelect = (value: string) => {
		const newFilters = {
			...filters,
			levels: updateFilters(filters.levels ?? [], value)
		};
		send({ type: SET_FILTERS, value: newFilters });
	};

	return (
		<VStack alignItems="start" sx={style?.container}>
			<HStack w="100%" justify="space-between">
				<HStack gap={1}>
					{title && (
						<Box fontFamily="heading" fontSize="md" fontWeight="bold">
							{title}
						</Box>
					)}
					<DataSourceBadges dataSources={[DATA_SOURCE_TRACKMAN, DATA_SOURCE_HAWKEYE]} />
				</HStack>
				{isShowFilters && (
					<Menu closeOnSelect={false} placement="left-start">
						<ButtonGroup
							isAttached
							variant={defaultFiltersSet ? "outline" : "solid"}
							colorScheme={defaultFiltersSet ? undefined : "blue"}
						>
							{!defaultFiltersSet && (
								<IconButton
									aria-label="Close"
									icon={<CloseIcon fill="white" />}
									onClick={resetFilters}
								/>
							)}
							<MenuButton
								as={IconButton}
								aria-label="Options"
								icon={<FilterAlt color={defaultFiltersSet ? "gray.500" : "white"} boxSize={5} />}
							>
								MenuItem
							</MenuButton>
						</ButtonGroup>
						<Portal>
							<MenuList minWidth="240px" maxHeight="md" overflow="scroll">
								<MenuOptionGroup title="Pitcher Throws" type="checkbox" value={filters.throws}>
									<MenuItemOption value={THROWS_L} onClick={() => handleThrowsSelect(THROWS_L)}>
										Left
									</MenuItemOption>
									<MenuItemOption value={THROWS_R} onClick={() => handleThrowsSelect(THROWS_R)}>
										Right
									</MenuItemOption>
								</MenuOptionGroup>
								<MenuDivider />
								<MenuOptionGroup title="Seasons">
									<VStack paddingLeft={4} paddingRight={4} sx={{ alignItems: "leading" }}>
										{minSeason === maxSeason && (
											<Tooltip hasArrow placement="top" label="Only one season of data exists">
												<HStack>
													<OutlineInfo color="gray.500" />
													<Text>{minSeason}</Text>
												</HStack>
											</Tooltip>
										)}
										{minSeason !== maxSeason && (
											<VStack>
												<RangeSlider
													value={[seasonFilters.minSeason, seasonFilters.maxSeason]}
													min={minSeason}
													max={maxSeason}
													step={1}
													onChange={(seasons: number[]) => {
														send({
															type: SET_FILTERS,
															value: {
																...filters,
																minSeason: seasons[0],
																maxSeason: seasons[1]
															}
														});
													}}
													onMouseEnter={() => setShowSeasonRangeTooltip(true)}
													onMouseLeave={() => setShowSeasonRangeTooltip(false)}
												>
													<RangeSliderTrack>
														<RangeSliderFilledTrack bg="black" />
													</RangeSliderTrack>
													<Tooltip
														hasArrow
														placement="top"
														isOpen={showSeasonRangeTooltip}
														label={seasonFilters.minSeason}
													>
														<RangeSliderThumb bg="gray.500" boxSize={3} index={0} />
													</Tooltip>
													<Tooltip
														hasArrow
														placement="top"
														isOpen={showSeasonRangeTooltip}
														label={seasonFilters.maxSeason}
													>
														<RangeSliderThumb bg="gray.500" boxSize={3} index={1} />
													</Tooltip>
												</RangeSlider>
												<Flex sx={{ width: "100%" }}>
													<Text fontSize="sm">{minSeason}</Text>
													<Spacer />
													<Text fontSize="sm">{maxSeason}</Text>
												</Flex>
											</VStack>
										)}
									</VStack>
								</MenuOptionGroup>
								<MenuOptionGroup
									title="Levels"
									type="checkbox"
									value={filters.levels ?? VALID_AMA_LEVELS}
								>
									{levelFilterOptions.map((option: IPlayerSeasonHitterGradesByTeamLkLevel) => (
										<MenuItemOption
											value={option.value}
											onClick={() => handleLevelSelect(option.value)}
										>
											<TeamLevelBadge
												level={option.value}
												displayName={getAmaLevelDisplayText(option.value)}
											/>
										</MenuItemOption>
									))}
								</MenuOptionGroup>
							</MenuList>
						</Portal>
					</Menu>
				)}
			</HStack>
			<Box sx={style?.tableContainer}>
				<Table<TAmaPositionPlayerFoundationalSkillsRow, keyof TAmaPositionPlayerFoundationalSkillsRow>
					columns={filteredColumns}
					data={combinedTableData}
					emptyDataDisplayText={"No Foundational Skills Data Found"}
					isLoadingData={isLoading || (!shouldFetchData && data?.isLoading)}
					isExpandableRows
					getCustomRowKeyFunction={(row: TAmaPositionPlayerFoundationalSkillsRow) => {
						// Child Rows
						if ("teamId" in row.combinedPlayerSeasonHitterGradesData)
							`${row.combinedPlayerSeasonHitterGradesData.season}-${row.combinedPlayerSeasonHitterGradesData.teamId}`;
						// Parent Rows
						return `${row.combinedPlayerSeasonHitterGradesData.season}`;
					}}
					defaultSortColumns={[
						{
							columnValue: "season",
							sortDirection: DESC
						},
						{
							columnValue: "level",
							sortDirection: DESC
						}
					]}
					getRowStyleFunction={(
						obj: TAmaPositionPlayerFoundationalSkillsRow,
						index: number,
						data: Array<TAmaPositionPlayerFoundationalSkillsRow>
					) => {
						if (
							index < data.length - 1 &&
							obj.combinedPlayerSeasonHitterGradesData.season !==
								data[index + 1].combinedPlayerSeasonHitterGradesData.season
						) {
							return {
								borderBottom: "1px solid !important",
								borderBottomColor: "gray.300 !important"
							};
						}
						return {};
					}}
					style={{ th: { textTransform: "none" }, parentTh: { textTransform: "none" } }}
					{...tableProps}
				/>
			</Box>
		</VStack>
	);
};

export default AmaPositionPlayerFoundationalSkillsTable;
