import React from "react";

import {
	TOOLTIP_STUFF_GRADE,
	TOOLTIP_STUFF_RA9,
	TOOLTIP_LOCATION_GRADE,
	TOOLTIP_LOCATION_RA9,
	TOOLTIP_INTRINSIC_GRADE,
	TOOLTIP_INTRINSIC_RA9
} from "_react/shared/_constants/tooltips";
import { getAmaLevelDisplayText, getAmaTeamDisplay } from "_react/shared/_helpers/stats";
import { BATS_OVERALL } from "_react/shared/data_models/seasonal_grades/_constants";
import { TColumn, TParentColumn } from "_react/shared/ui/presentation/components/Table/_types";
import { VALUE_TYPE_NUMBER, VALUE_TYPE_STRING } from "_react/shared/ui/presentation/components/Table/_constants";
import { TValueType } from "_react/shared/ui/presentation/components/Table/_types";
import { formatRa9, format2080Grade } from "_react/shared/data_models/seasonal_grades/_helpers";
import { formatPercentage } from "_react/shared/ui/presentation/components/stat/shared/_helpers";
import PitchTypeLabel from "_react/shared/ui/presentation/components/PitchTypeLabel/PitchTypeLabel";
import { ICON_CIRCLE } from "_react/shared/ui/presentation/components/PitchTypeLabel/_constants";
import TeamLevelBadge from "_react/shared/ui/presentation/components/TeamLevelBadge/TeamLevelBadge";

import {
	TAmaPitcherFoundationalSkillsRow,
	TStuffOrShapeValue
} from "_react/shared/ui/data/tables/AmaPitcherFoundationalSkillsTable/_types";

// Stuff vs. Shape Column Options
export const STUFF = "stuff";
export const SHAPE = "shape";

// Non-Column Constants

export const NUM_DISPLAY_SEASONS = 3;

// Table Columns

const NULL_FILLER_TEXT = "";

const SEASON_COLUMN = {
	id: "season",
	value: "season",
	label: "Season",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TAmaPitcherFoundationalSkillsRow) => row.playerSeasonArsenalScoresByTeam.season
};

const TEAM_COLUMN = {
	id: "team",
	value: "team",
	label: "Team",
	isMobile: true,
	valueType: VALUE_TYPE_STRING as TValueType,
	getValueFunction: (row: TAmaPitcherFoundationalSkillsRow) =>
		getAmaTeamDisplay(row.playerSeasonArsenalScoresByTeam.team),
	getSortValueFunction: (row: TAmaPitcherFoundationalSkillsRow) =>
		`${row.playerSeasonArsenalScoresByTeam.team?.name ??
			row.playerSeasonArsenalScoresByTeam.team?.trackmanTeam?.level}-${
			row.playerSeasonArsenalScoresByTeam.teamId
		}`
};

const LEVEL_COLUMN = {
	id: "level",
	value: "level",
	label: "Level",
	isMobile: true,
	valueType: VALUE_TYPE_STRING as TValueType,
	getValueFunction: (row: TAmaPitcherFoundationalSkillsRow) => (
		<TeamLevelBadge
			level={row.playerSeasonArsenalScoresByTeam.team?.level}
			displayName={getAmaLevelDisplayText(row.playerSeasonArsenalScoresByTeam.team?.level)}
			nullFillerText={NULL_FILLER_TEXT}
		/>
	),
	getSortValueFunction: (row: TAmaPitcherFoundationalSkillsRow) =>
		row.playerSeasonArsenalScoresByTeam.team?.levelRel?.sortOrder ?? Number.MAX_SAFE_INTEGER
};

const BATS_COLUMN = {
	id: "bats",
	value: "bats",
	label: "Bats",
	isMobile: true,
	valueType: VALUE_TYPE_STRING as TValueType,
	getValueFunction: (row: TAmaPitcherFoundationalSkillsRow) =>
		row.playerSeasonArsenalScoresByTeam.bats ?? NULL_FILLER_TEXT
};

const PITCH_TYPE_COLUMN = {
	id: "pitchType",
	value: "pitchType",
	label: "Type",
	isMobile: true,
	valueType: VALUE_TYPE_STRING as TValueType,
	getValueFunction: (row: TAmaPitcherFoundationalSkillsRow) =>
		row.playerSeasonArsenalScoresByTeam.lkPitchType?.label &&
		row.playerSeasonArsenalScoresByTeam.lkPitchType?.abbreviation ? (
			<PitchTypeLabel
				label={row.playerSeasonArsenalScoresByTeam.lkPitchType.label}
				abbreviation={row.playerSeasonArsenalScoresByTeam.lkPitchType.abbreviation}
				shape={ICON_CIRCLE}
			/>
		) : (
			"Overall"
		),
	getSortValueFunction: (row: TAmaPitcherFoundationalSkillsRow) =>
		row.playerSeasonArsenalScoresByTeam.lkPitchType?.sortOrder ?? 0
};

const PITCHES_COLUMN = {
	id: "pitches",
	value: "pitches",
	label: "Total",
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TAmaPitcherFoundationalSkillsRow) =>
		row.playerSeasonArsenalScoresByTeam.total ?? NULL_FILLER_TEXT
};

const USAGE_COLUMN = {
	id: "usage",
	value: "usage",
	label: "Usage",
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TAmaPitcherFoundationalSkillsRow) =>
		formatPercentage(row.playerSeasonArsenalScoresByTeam.usage) ?? NULL_FILLER_TEXT
};

const STUFF_GRADE_COLUMN = {
	id: "stuffGrade",
	value: "stuffGrade",
	label: "Grade",
	tooltip: TOOLTIP_STUFF_GRADE,
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TAmaPitcherFoundationalSkillsRow) =>
		format2080Grade(row.playerSeasonArsenalScoresByTeam.stuffGrade, NULL_FILLER_TEXT)
};

const STUFF_RA9_COLUMN = {
	id: "stuffRa9",
	value: "stuffRa9",
	label: "RA9",
	tooltip: TOOLTIP_STUFF_RA9,
	isMobile: false,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TAmaPitcherFoundationalSkillsRow) =>
		formatRa9(row.playerSeasonArsenalScoresByTeam.stuffRa9, NULL_FILLER_TEXT)
};

const SHAPE_GRADE_COLUMN = {
	id: "shapeGrade",
	value: "shapeGrade",
	label: "Grade",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TAmaPitcherFoundationalSkillsRow) =>
		format2080Grade(row.playerSeasonArsenalScoresByTeam.shapeGrade, NULL_FILLER_TEXT)
};

const SHAPE_RA9_COLUMN = {
	id: "shapeRa9",
	value: "shapeRa9",
	label: "RA9",
	isMobile: false,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TAmaPitcherFoundationalSkillsRow) =>
		formatRa9(row.playerSeasonArsenalScoresByTeam.shapeRa9, NULL_FILLER_TEXT)
};

const LOCATIONS_GRADE_COLUMN = {
	id: "locationsGrade",
	value: "locationsGrade",
	label: "Grade",
	tooltip: TOOLTIP_LOCATION_GRADE,
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TAmaPitcherFoundationalSkillsRow) =>
		format2080Grade(row.playerSeasonArsenalScoresByTeam.locationGrade, NULL_FILLER_TEXT)
};

const LOCATIONS_RA9_COLUMN = {
	id: "locationsRa9",
	value: "locationsRa9",
	label: "RA9",
	tooltip: TOOLTIP_LOCATION_RA9,
	isMobile: false,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TAmaPitcherFoundationalSkillsRow) =>
		formatRa9(row.playerSeasonArsenalScoresByTeam.locationRa9, NULL_FILLER_TEXT)
};

const INTRINSIC_VALUE_GRADE_COLUMN = {
	id: "intrinsicValueGrade",
	value: "intrinsicValueGrade",
	label: "Grade",
	tooltip: TOOLTIP_INTRINSIC_GRADE,
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TAmaPitcherFoundationalSkillsRow) =>
		format2080Grade(row.playerSeasonArsenalScoresByTeam.intrinsicGrade, NULL_FILLER_TEXT)
};

const INTRINSIC_VALUE_RA9_COLUMN = {
	id: "intrinsicValueRa9",
	value: "intrinsicValueRa9",
	label: "RA9",
	tooltip: TOOLTIP_INTRINSIC_RA9,
	isMobile: false,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TAmaPitcherFoundationalSkillsRow) =>
		formatRa9(row.playerSeasonArsenalScoresByTeam.intrinsicRa9, NULL_FILLER_TEXT)
};

export const PITCHER_FOUNDATIONAL_SKILLS_COLUMNS = (
	batsFilter: string,
	displayStuffOrShape?: TStuffOrShapeValue
): Array<TColumn<TAmaPitcherFoundationalSkillsRow, keyof TAmaPitcherFoundationalSkillsRow>> => {
	const stuffOrShapeColumns =
		displayStuffOrShape === SHAPE ? [SHAPE_GRADE_COLUMN, SHAPE_RA9_COLUMN] : [STUFF_GRADE_COLUMN, STUFF_RA9_COLUMN];
	const columns: Array<TColumn<TAmaPitcherFoundationalSkillsRow, keyof TAmaPitcherFoundationalSkillsRow>> = [
		SEASON_COLUMN,
		TEAM_COLUMN,
		LEVEL_COLUMN
	];
	if (batsFilter !== BATS_OVERALL) {
		columns.push(BATS_COLUMN);
	}
	return columns.concat([
		PITCH_TYPE_COLUMN,
		PITCHES_COLUMN,
		USAGE_COLUMN,
		...stuffOrShapeColumns,
		LOCATIONS_GRADE_COLUMN,
		LOCATIONS_RA9_COLUMN,
		INTRINSIC_VALUE_GRADE_COLUMN,
		INTRINSIC_VALUE_RA9_COLUMN
	]);
};

export const PITCHER_FOUNDATIONAL_SKILLS_PARENT_COLUMNS = (
	displayStuffOrShape?: TStuffOrShapeValue
): Array<TParentColumn> => {
	const stuffOrShapeParentColumn =
		displayStuffOrShape === SHAPE
			? { label: "Shape", id: "shape", childColumnIds: ["shapeGrade", "shapeRa9"] }
			: { label: "Stuff", id: "stuff", childColumnIds: ["stuffGrade", "stuffRa9"] };
	return [
		{ label: "", id: "general", childColumnIds: ["season", "team", "level", "bats"] },
		{ label: "Pitches", id: "pitches", childColumnIds: ["pitchType", "pitches", "usage"] },
		stuffOrShapeParentColumn,
		{ label: "Locations", id: "locations", childColumnIds: ["locationsGrade", "locationsRa9"] },
		{ label: "Intrinsic", id: "intrinsic", childColumnIds: ["intrinsicValueGrade", "intrinsicValueRa9"] }
	];
};
