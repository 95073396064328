import { IPlayerSeasonArsenalScoresByTeamSchema } from "_react/shared/data_models/arsenal_scores/_types";
import { BATS_L, BATS_OVERALL, BATS_R } from "_react/shared/data_models/baseline_hit_probs/_constants";
import {
	IPitchOutcomeObservedPitchApiResponse,
	IPitchOutcomeObservedPitchByTeam
} from "_react/shared/data_models/pitch_outcome_observed/_types";
import {
	IPitchOutcomeProbabilitiesPitch,
	IPitchOutcomeProbabilitiesPitchByTeam
} from "_react/shared/data_models/pitch_outcome_probabilities/_types";
import { ICombinedPitcherPitchOutcomesData } from "_react/shared/ui/data/tables/AmaPitcherPitchOutcomesTable/_types";

//
// DATA PREPROCESSING
//

// Converts raw arsenal scores byteam data to a record mapping
// season-teamId-gameType-pitchType to ICombinedPitcherPitchOutcomesData
export const createArsenalScoresByTeamRecord = (
	playerSeasonArsenalScoresByTeam: Array<IPlayerSeasonArsenalScoresByTeamSchema>
): Record<string, ICombinedPitcherPitchOutcomesData> => {
	// Populate a record to track relevant playerSeasonArsenalScoresByTeam instances
	// The record is keyed on "season-teamId-gameType-pitchType"
	return playerSeasonArsenalScoresByTeam.reduce(
		(acc: Record<string, ICombinedPitcherPitchOutcomesData>, s: IPlayerSeasonArsenalScoresByTeamSchema) => {
			// If season-gameType-pitchType does not exist, create an entry in the record
			if (!(`${s.season}-${s.teamId}-${s.gameType}-${s.pitchType}` in acc)) {
				acc[`${s.season}-${s.teamId}-${s.gameType}-${s.pitchType}`] = {
					season: s.season,
					gameType: s.gameType,
					pitchType: s.pitchType,
					teamId: s.teamId,

					bips: s.bats === BATS_OVERALL ? s.bips : 0,
					bipsVl: s.bats === BATS_L ? s.bips : 0,
					bipsVr: s.bats === BATS_R ? s.bips : 0,

					exitVelo: s.bats === BATS_OVERALL ? s.exitVelo : 0,
					exitVeloVl: s.bats === BATS_L ? s.exitVelo : 0,
					exitVeloVr: s.bats === BATS_R ? s.exitVelo : 0,

					launchAngle: s.bats === BATS_OVERALL ? s.launchAngle : 0,
					launchAngleVl: s.bats === BATS_L ? s.launchAngle : 0,
					launchAngleVr: s.bats === BATS_R ? s.launchAngle : 0
				};
			}
			// Otherwise, aggregate new row with old row
			else {
				const prev: ICombinedPitcherPitchOutcomesData =
					acc[`${s.season}-${s.teamId}-${s.gameType}-${s.pitchType}`];
				acc[`${s.season}-${s.teamId}-${s.gameType}-${s.pitchType}`] = aggregateArsenalScoresRows(prev, s);
			}
			return acc;
		},
		{}
	);
};

export const aggregateArsenalScoresRows = (
	prev: ICombinedPitcherPitchOutcomesData,
	s: IPlayerSeasonArsenalScoresByTeamSchema
): ICombinedPitcherPitchOutcomesData => {
	return {
		...prev,
		bips: s.bats === BATS_OVERALL ? s.bips : prev.bips,
		bipsVl: s.bats === BATS_L ? s.bips : prev.bipsVl,
		bipsVr: s.bats === BATS_R ? s.bips : prev.bipsVr,

		exitVelo: s.bats === BATS_OVERALL ? s.exitVelo : prev.exitVelo,
		exitVeloVl: s.bats === BATS_L ? s.exitVelo : prev.exitVeloVl,
		exitVeloVr: s.bats === BATS_R ? s.exitVelo : prev.exitVeloVr,

		launchAngle: s.bats === BATS_OVERALL ? s.launchAngle : prev.launchAngle,
		launchAngleVl: s.bats === BATS_L ? s.launchAngle : prev.launchAngleVl,
		launchAngleVr: s.bats === BATS_R ? s.launchAngle : prev.launchAngleVr
	};
};

// Converts raw pitch outcome observed data to a record mapping
// season-teamId-gameType-pitchType to ICombinedPitcherPitchOutcomesData
export const createPitchOutcomeObservedByTeamRecord = (
	pichOutcomeObservedByTeam: Array<IPitchOutcomeObservedPitchByTeam>
): Record<string, ICombinedPitcherPitchOutcomesData> => {
	// Populate a record to track relevant pitchOutcomeObservedByTeam instances
	// The record is keyed on "season-teamId-gameType-pitchType"
	return pichOutcomeObservedByTeam.reduce(
		(acc: Record<string, ICombinedPitcherPitchOutcomesData>, s: IPitchOutcomeObservedPitchByTeam) => {
			// If season-gameType-pitchType does not exist, create an entry in the record
			if (!(`${s.season}-${s.teamId}-${s.gameType}-${s.pitchType}` in acc)) {
				acc[`${s.season}-${s.teamId}-${s.gameType}-${s.pitchType}`] = {
					season: s.season,
					gameType: s.gameType,
					pitchType: s.pitchType,
					lkPitchType: s.lkPitchType,
					teamId: s.teamId,
					team: s.team,
					total: s.bats === BATS_OVERALL ? s.total : 0,
					totalVl: s.bats === BATS_L ? s.total : 0,
					totalVr: s.bats === BATS_R ? s.total : 0,

					swingPct: s.bats === BATS_OVERALL ? s.swingPct : 0,
					swingPctVl: s.bats === BATS_L ? s.swingPct : 0,
					swingPctVr: s.bats === BATS_R ? s.swingPct : 0,

					whiffPct: s.bats === BATS_OVERALL ? s.whiffPct : 0,
					whiffPctVl: s.bats === BATS_L ? s.whiffPct : 0,
					whiffPctVr: s.bats === BATS_R ? s.whiffPct : 0,

					chasePct: s.bats === BATS_OVERALL ? s.chasePct : 0,
					chasePctVl: s.bats === BATS_L ? s.chasePct : 0,
					chasePctVr: s.bats === BATS_R ? s.chasePct : 0,

					cswPct: s.bats === BATS_OVERALL ? s.cswPct : 0,
					cswPctVl: s.bats === BATS_L ? s.cswPct : 0,
					cswPctVr: s.bats === BATS_R ? s.cswPct : 0
				};
			}
			// Otherwise, aggregate new row with old row
			else {
				const prev: ICombinedPitcherPitchOutcomesData =
					acc[`${s.season}-${s.teamId}-${s.gameType}-${s.pitchType}`];
				acc[`${s.season}-${s.teamId}-${s.gameType}-${s.pitchType}`] = aggregatePitchOutcomeObservedRows(
					prev,
					s
				);
			}
			return acc;
		},
		{}
	);
};

const aggregatePitchOutcomeObservedRows = (
	prev: ICombinedPitcherPitchOutcomesData,
	s: IPitchOutcomeObservedPitchApiResponse
): ICombinedPitcherPitchOutcomesData => {
	return {
		...prev,
		total: s.bats === BATS_OVERALL ? s.total : prev.total,
		totalVl: s.bats === BATS_L ? s.total : prev.totalVl,
		totalVr: s.bats === BATS_R ? s.total : prev.totalVr,

		swingPct: s.bats === BATS_OVERALL ? s.swingPct : prev.swingPct,
		swingPctVl: s.bats === BATS_L ? s.swingPct : prev.swingPctVl,
		swingPctVr: s.bats === BATS_R ? s.swingPct : prev.swingPctVr,

		whiffPct: s.bats === BATS_OVERALL ? s.whiffPct : prev.whiffPct,
		whiffPctVl: s.bats === BATS_L ? s.whiffPct : prev.whiffPctVl,
		whiffPctVr: s.bats === BATS_R ? s.whiffPct : prev.whiffPctVr,

		chasePct: s.bats === BATS_OVERALL ? s.chasePct : prev.chasePct,
		chasePctVl: s.bats === BATS_L ? s.chasePct : prev.chasePctVl,
		chasePctVr: s.bats === BATS_R ? s.chasePct : prev.chasePctVr,

		cswPct: s.bats === BATS_OVERALL ? s.cswPct : prev.cswPct,
		cswPctVl: s.bats === BATS_L ? s.cswPct : prev.cswPctVl,
		cswPctVr: s.bats === BATS_R ? s.cswPct : prev.cswPctVr
	};
};

// Converts raw pitch outcome probabilities data to a record mapping
// season-gameType-pitchType to ICombinedPitcherPitchOutcomesData
export const createPitchOutcomeProbabilitiesByTeamRecord = (
	pichOutcomeProbabilitiesByTeam: Array<IPitchOutcomeProbabilitiesPitchByTeam>
): Record<string, ICombinedPitcherPitchOutcomesData> => {
	// Populate a record to track relevant pichOutcomeProbabilitiesByTeam instances
	// The record is keyed on "season-teamId-gameType-pitchType"
	return pichOutcomeProbabilitiesByTeam.reduce(
		(acc: Record<string, ICombinedPitcherPitchOutcomesData>, s: IPitchOutcomeProbabilitiesPitchByTeam) => {
			// If season-gameType-pitchType does not exist, create an entry in the record
			if (!(`${s.season}-${s.teamId}-${s.gameType}-${s.pitchType}` in acc)) {
				acc[`${s.season}-${s.teamId}-${s.gameType}-${s.pitchType}`] = {
					season: s.season,
					gameType: s.gameType,
					pitchType: s.pitchType,
					teamId: s.teamId,

					swingPctPIntrinsic: s.bats === BATS_OVERALL ? s.pSwingIntrinsic : 0,
					swingPctPIntrinsicVr: s.bats === BATS_R ? s.pSwingIntrinsic : 0,
					swingPctPIntrinsicVl: s.bats === BATS_L ? s.pSwingIntrinsic : 0,
					swingPctPStuff: s.bats === BATS_OVERALL ? s.pSwingStuff : 0,
					swingPctPStuffVr: s.bats === BATS_R ? s.pSwingStuff : 0,
					swingPctPStuffVl: s.bats === BATS_L ? s.pSwingStuff : 0,

					whiffPctPIntrinsic: s.bats === BATS_OVERALL ? s.pWhiffIntrinsic : 0,
					whiffPctPIntrinsicVr: s.bats === BATS_R ? s.pWhiffIntrinsic : 0,
					whiffPctPIntrinsicVl: s.bats === BATS_L ? s.pWhiffIntrinsic : 0,
					whiffPctPStuff: s.bats === BATS_OVERALL ? s.pWhiffStuff : 0,
					whiffPctPStuffVr: s.bats === BATS_R ? s.pWhiffStuff : 0,
					whiffPctPStuffVl: s.bats === BATS_L ? s.pWhiffStuff : 0,

					chasePctPIntrinsic: s.bats === BATS_OVERALL ? s.pChaseIntrinsic : 0,
					chasePctPIntrinsicVr: s.bats === BATS_R ? s.pChaseIntrinsic : 0,
					chasePctPIntrinsicVl: s.bats === BATS_L ? s.pChaseIntrinsic : 0,
					chasePctPStuff: s.bats === BATS_OVERALL ? s.pChaseStuff : 0,
					chasePctPStuffVr: s.bats === BATS_R ? s.pChaseStuff : 0,
					chasePctPStuffVl: s.bats === BATS_L ? s.pChaseStuff : 0,

					cswPctPIntrinsic: s.bats === BATS_OVERALL ? s.pCswIntrinsic : 0,
					cswPctPIntrinsicVr: s.bats === BATS_R ? s.pCswIntrinsic : 0,
					cswPctPIntrinsicVl: s.bats === BATS_L ? s.pCswIntrinsic : 0,
					cswPctPStuff: s.bats === BATS_OVERALL ? s.pCswStuff : 0,
					cswPctPStuffVr: s.bats === BATS_R ? s.pCswStuff : 0,
					cswPctPStuffVl: s.bats === BATS_L ? s.pCswStuff : 0
				};
			}
			// Otherwise, aggregate new row with old row
			else {
				const prev: ICombinedPitcherPitchOutcomesData =
					acc[`${s.season}-${s.teamId}-${s.gameType}-${s.pitchType}`];
				acc[`${s.season}-${s.teamId}-${s.gameType}-${s.pitchType}`] = aggregatePitchOutcomeProbabilitiesRows(
					prev,
					s
				);
			}

			return acc;
		},
		{}
	);
};

const aggregatePitchOutcomeProbabilitiesRows = (
	prev: ICombinedPitcherPitchOutcomesData,
	s: IPitchOutcomeProbabilitiesPitch
): ICombinedPitcherPitchOutcomesData => {
	return {
		...prev,

		swingPctPIntrinsic: s.bats === BATS_OVERALL ? s.pSwingIntrinsic : prev.swingPctPIntrinsic,
		swingPctPIntrinsicVr: s.bats === BATS_R ? s.pSwingIntrinsic : prev.swingPctPIntrinsicVr,
		swingPctPIntrinsicVl: s.bats === BATS_L ? s.pSwingIntrinsic : prev.swingPctPIntrinsicVl,
		swingPctPStuff: s.bats === BATS_OVERALL ? s.pSwingStuff : prev.swingPctPStuff,
		swingPctPStuffVr: s.bats === BATS_R ? s.pSwingStuff : prev.swingPctPStuffVr,
		swingPctPStuffVl: s.bats === BATS_L ? s.pSwingStuff : prev.swingPctPStuffVl,

		whiffPctPIntrinsic: s.bats === BATS_OVERALL ? s.pWhiffIntrinsic : prev.whiffPctPIntrinsic,
		whiffPctPIntrinsicVr: s.bats === BATS_R ? s.pWhiffIntrinsic : prev.whiffPctPIntrinsicVr,
		whiffPctPIntrinsicVl: s.bats === BATS_L ? s.pWhiffIntrinsic : prev.whiffPctPIntrinsicVl,
		whiffPctPStuff: s.bats === BATS_OVERALL ? s.pWhiffStuff : prev.whiffPctPStuff,
		whiffPctPStuffVr: s.bats === BATS_R ? s.pWhiffStuff : prev.whiffPctPStuffVr,
		whiffPctPStuffVl: s.bats === BATS_L ? s.pWhiffStuff : prev.whiffPctPStuffVl,

		chasePctPIntrinsic: s.bats === BATS_OVERALL ? s.pChaseIntrinsic : prev.chasePctPIntrinsic,
		chasePctPIntrinsicVr: s.bats === BATS_R ? s.pChaseIntrinsic : prev.chasePctPIntrinsicVr,
		chasePctPIntrinsicVl: s.bats === BATS_L ? s.pChaseIntrinsic : prev.chasePctPIntrinsicVl,
		chasePctPStuff: s.bats === BATS_OVERALL ? s.pChaseStuff : prev.chasePctPStuff,
		chasePctPStuffVr: s.bats === BATS_R ? s.pChaseStuff : prev.chasePctPStuffVr,
		chasePctPStuffVl: s.bats === BATS_L ? s.pChaseStuff : prev.chasePctPStuffVl,

		cswPctPIntrinsic: s.bats === BATS_OVERALL ? s.pCswIntrinsic : prev.cswPctPIntrinsic,
		cswPctPIntrinsicVr: s.bats === BATS_R ? s.pCswIntrinsic : prev.cswPctPIntrinsicVr,
		cswPctPIntrinsicVl: s.bats === BATS_L ? s.pCswIntrinsic : prev.cswPctPIntrinsicVl,
		cswPctPStuff: s.bats === BATS_OVERALL ? s.pCswStuff : prev.cswPctPStuff,
		cswPctPStuffVr: s.bats === BATS_R ? s.pCswStuff : prev.cswPctPStuffVr,
		cswPctPStuffVl: s.bats === BATS_L ? s.pCswStuff : prev.cswPctPStuffVl
	};
};

export const mergePitchOutcomeAggregations = (
	playerSeasonArsenalScoresByTeam: Record<string, ICombinedPitcherPitchOutcomesData>,
	pitchOutcomeObserved: Record<string, ICombinedPitcherPitchOutcomesData>,
	pitchOutcomeProbabilities: Record<string, ICombinedPitcherPitchOutcomesData>
): Record<string, ICombinedPitcherPitchOutcomesData> => {
	const partiallyMergedRecord: Record<string, ICombinedPitcherPitchOutcomesData> = Object.values(
		pitchOutcomeObserved
	).reduce((acc: Record<string, ICombinedPitcherPitchOutcomesData>, o: ICombinedPitcherPitchOutcomesData) => {
		// Get record
		const key = `${o.season}-${o.teamId}-${o.gameType}-${o.pitchType}`;
		const record = pitchOutcomeProbabilities[key];
		acc[key] = {
			...o,
			...record
		};
		return acc;
	}, {});
	return Object.values(playerSeasonArsenalScoresByTeam).reduce(
		(acc: Record<string, ICombinedPitcherPitchOutcomesData>, o: ICombinedPitcherPitchOutcomesData) => {
			// Get record
			const key = `${o.season}-${o.teamId}-${o.gameType}-${o.pitchType}`;
			const record = partiallyMergedRecord[key];
			if (record) {
				acc[key] = {
					...o,
					...record
				};
			}
			return acc;
		},
		{}
	);
};
