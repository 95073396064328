import React from "react";

import { RECORD_STATUS_INITIALIZING, FIELD_STYLE_TRAILING } from "_react/shared/data_models/reports/_constants";
import {
	IReport,
	IReportField,
	IReportSection,
	IReportCategory,
	IReportCollection,
	IReportValue,
	IReportCollectionFieldHeaderStyle
} from "_react/shared/data_models/reports/_types";
import {
	TDeleteCollectionEntryData,
	TFieldDisplayGroup,
	TReportFieldGroup,
	TReportFieldRowBuilderState,
	TFieldDisplayPurpose
} from "_react/reports/_types";
import { showField, showCategory, showSection } from "_react/reports/directives/display";
import ReportReviewFieldValue from "_react/reports/views/review/ReportReviewFieldValue";
import { FIELD_DISPLAY_INPUT, FIELD_DISPLAY_COLLECTION_HEADER, FIELD_DISPLAY_REVIEW } from "_react/reports/_constants";

/*
 * Report Document Helpers
 */

export const getFields = (report: IReport, includingHidden = true) => {
	// Traverses the report tree and extracts all fields into a flat array
	return report.sections
		.filter((section: IReportSection) => includingHidden || showSection(report, section))
		.reduce((f: IReportField[], section: IReportSection) => {
			return [
				...f,
				...section.categories
					.filter((category: IReportCategory) => includingHidden || showCategory(report, category))
					.reduce((f: IReportField[], category: IReportCategory) => {
						return [
							...f,
							...category.fields
								.filter(
									(field: IReportField) =>
										includingHidden || showField(report, field, field.value, field.collectionIndex)
								)
								.reduce((f: IReportField[], field: IReportField) => {
									return [
										...f,
										field,
										...(field.collections ?? []).reduce(
											(f: IReportField[], collection: IReportCollection) => {
												return [
													...f,
													...collection.fields
														.filter(
															(field: IReportField) =>
																includingHidden ||
																showField(
																	report,
																	field,
																	field.value,
																	field.collectionIndex
																)
														)
														.reduce((f: IReportField[], field: IReportField) => {
															return [...f, field];
														}, [])
												];
											},
											[]
										)
									];
								}, [])
						];
					}, [])
			];
		}, []);
};

export const getField = (report: IReport, key: string, isCode = false, collectionIndex = -1) => {
	// Extracts a specific field from the report
	return getFields(report).find(
		(field: IReportField) =>
			(!isCode && field.id === key && field.collectionIndex === collectionIndex) ||
			(isCode && field.code === key && field.collectionIndex === collectionIndex)
	);
};

export const getPrimaryFieldForCollection = (report: IReport, key: string, isCode = false, collectionIndex = -1) => {
	// Given a collection field, get the primary field for that collection

	// Find parent field
	const parentField = getFields(report).find((field: IReportField) =>
		field.collectionFields?.find(
			(field: IReportField) => (!isCode && field.id === key) || (isCode && field.code === key)
		)
	);

	// Get Collection
	if (parentField?.collections && parentField.collections.length > collectionIndex) {
		return parentField.collections[collectionIndex].fields.find((field: IReportField) => field.primaryField);
	}

	return null;
};

export const getFieldValue = (report: IReport, key: string, isCode = false, collectionIndex = -1) => {
	// Extracts a specific field's value from the report
	return getFields(report).find(
		(field: IReportField) =>
			(!isCode && field.id === key && field.collectionIndex === collectionIndex) ||
			(isCode && field.code === key && field.collectionIndex === collectionIndex)
	)?.value;
};

export const getReportReviewFieldValue = (
	report: IReport,
	key: string,
	isCode = false,
	collectionIndex = -1,
	purpose: TFieldDisplayPurpose = FIELD_DISPLAY_REVIEW
) => {
	const field = getField(report, key, isCode, collectionIndex);
	return field ? <ReportReviewFieldValue report={report} field={field} purpose={purpose} /> : undefined;
};

export const getSectionForField = (report: IReport | undefined, field: IReportField): IReportSection | undefined => {
	return report?.sections.find((section: IReportSection) => getCategoryForField(section.categories, field));
};

export const getCategoryForField = (
	categories: IReportCategory[] | undefined,
	field: IReportField
): IReportCategory | undefined => {
	return categories?.find(
		(category: IReportCategory) =>
			category.fields.find((f: IReportField) => {
				if (f.id === field.id) return true;
				return (
					f.collections?.find(
						(collection: IReportCollection) =>
							collection.fields.find(
								(f: IReportField) => f.id === field.id && f.collectionIndex === field.collectionIndex
							) != null
					) != null
				);
			}) != null
	);
};

export const setReportValues = (report: IReport, values: IReportValue[]): IReport => {
	const newReport = {
		...report, // Existing Report Metadata
		sections: report.sections.map((section: IReportSection) => ({
			// Iterate over each section
			...section, // Existing Section Metadata
			categories: section.categories.map((category: IReportCategory) => ({
				// Iterate over each category
				...category, // Existing Category Metadata
				fields: category.fields.map((field: IReportField) => {
					// Iterate over each field

					// Stage updated field
					let newField = field;

					// Build a list of field ids for all child fields (in the case this is a collection field)
					const childFieldIds = field.collectionFields?.map((field: IReportField) => field.id) ?? [];

					// Iterate over each value to update
					values.forEach((value: IReportValue) => {
						const updateFieldId = value.fieldId;
						const updateFieldValue = value.value;
						const updateFieldCollectionIndex = value.collectionIndex;

						// If field id matches (and the update's collection index is -1), then it's not a collection field, so update the value property
						if (field.id === updateFieldId && updateFieldCollectionIndex === -1) {
							// Populate field value
							newField = {
								...field,
								value: updateFieldValue
							};
						} else if (childFieldIds.includes(updateFieldId)) {
							// Field is a collection, and the value being updated is one of it's children
							if (newField.collections && newField.collections?.length > updateFieldCollectionIndex) {
								// Collection entry already exists for the field being updated

								// Find the field's index within the collection entry
								const fieldIndex = newField.collections[updateFieldCollectionIndex].fields.findIndex(
									(field: IReportField) => field.id === updateFieldId
								);

								// Extract the existing field being updated
								const existingField =
									newField.collections[updateFieldCollectionIndex].fields[fieldIndex];

								// Build the new field
								const newCollectionEntry = {
									...existingField,
									value: updateFieldValue,
									collectionIndex: updateFieldCollectionIndex
								};

								// Place the updated field within the parent's field collection
								newField.collections[updateFieldCollectionIndex].fields = [
									// Fields in the collection above the field being updated
									...newField.collections[updateFieldCollectionIndex].fields.slice(0, fieldIndex),
									// The field being updated
									newCollectionEntry,
									// Fields in the collection below the field being updated
									...newField.collections[updateFieldCollectionIndex].fields.slice(fieldIndex + 1)
								];
							} else if (newField.collectionFields) {
								// The collection entry does not yet exist, create and append it to the field's collection

								// Build a new collection entry by iterating over the field's "collection fields" (which is just an array of the different fields that are included in each collection entry), while setting the value of collection entry's field that is being updated (the rest have no value)
								const newCollectionEntryFields = newField.collectionFields.map(
									(field: IReportField) => ({
										...field,
										value: field.id === updateFieldId ? updateFieldValue : null,
										collectionIndex: updateFieldCollectionIndex
									})
								);

								// Append the new entry to the field's collection
								newField.collections?.push({
									collectionIndex: updateFieldCollectionIndex,
									fields: newCollectionEntryFields
								});
							}
						}
					});
					// Return updated (or skipped) field
					return newField;
				})
			}))
		}))
	};

	// Return the new report object
	return newReport;
};

export const deleteReportCollectionEntry = (report: IReport, deleteRequest: TDeleteCollectionEntryData): IReport => {
	const newReport = {
		...report, // Existing Report Metadata
		sections: report.sections.map((section: IReportSection) => ({
			// Iterate over each section
			...section, // Existing Section Metadata
			categories: section.categories.map((category: IReportCategory) => ({
				// Iterate over each category
				...category, // Existing Category Metadata
				fields: category.fields.map((field: IReportField) => {
					// Iterate over each field

					// Stage updated field
					const newField = field;

					if (deleteRequest.parentFieldId === field.id) {
						// This is the field where the collection entry exists

						// Filter out deleted collection entry, and decrement collection index of subsequent collection entries
						newField.collections = newField.collections
							?.filter(
								(collection: IReportCollection) =>
									collection.collectionIndex !== deleteRequest.collectionIndex
							)
							.map((collection: IReportCollection) => {
								return {
									// Decrement entry's overall collection index
									collectionIndex:
										collection.collectionIndex < deleteRequest.collectionIndex
											? collection.collectionIndex
											: collection.collectionIndex - 1,
									// Decrement each field's collection index in the entry
									fields: collection.fields.map((field: IReportField) => {
										return {
											...field,
											collectionIndex:
												field.collectionIndex! < deleteRequest.collectionIndex
													? field.collectionIndex
													: field.collectionIndex! - 1
										};
									})
								};
							});
					}

					// Return updated (or skipped) field
					return newField;
				})
			}))
		}))
	};

	// Return the new report object
	return newReport;
};

export const isReportPublished = (report?: IReport): boolean => {
	return report?.publishDate != null;
};

export const isReportStatusInitializing = (report?: IReport): boolean => {
	return report?.recordStatus === RECORD_STATUS_INITIALIZING;
};

/*
 * Display Helpers
 */

export const filterSections = (report: IReport | undefined): IReportSection[] | undefined =>
	report?.sections.filter((section: IReportSection) => showSection(report, section));

export const filterCategories = (report: IReport, section: IReportSection): IReportCategory[] =>
	section.categories.filter((category: IReportCategory) => showCategory(report, category));

export const filterFields = (
	report: IReport,
	category: IReportCategory,
	showExperimentalFields = false,
	purpose: TFieldDisplayPurpose = FIELD_DISPLAY_INPUT
): IReportField[] =>
	category.fields.filter(
		(field: IReportField) =>
			showField(report, field, field.value, field.collectionIndex, purpose) &&
			(field.permissions == null || showExperimentalFields)
	);

export const filterCollectionFields = (
	report: IReport,
	collection: IReportCollection,
	showExperimentalFields = false,
	purpose: TFieldDisplayPurpose = FIELD_DISPLAY_INPUT
): IReportField[] =>
	collection.fields.filter(
		(field: IReportField) =>
			showField(report, field, field.value, field.collectionIndex, purpose) &&
			(field.permissions == null || showExperimentalFields)
	);

export const getHeaderCollectionFields = (
	report: IReport,
	collection: IReportCollection,
	trailing = false,
	showExperimentalFields = false
): IReportField[] =>
	collection.fields
		.filter((field: IReportField) => {
			const styles: IReportCollectionFieldHeaderStyle[] =
				(field.collapsedHeaderStyle?.split(",") as IReportCollectionFieldHeaderStyle[]) ?? [];

			return (
				showField(report, field, field.value, field.collectionIndex, FIELD_DISPLAY_COLLECTION_HEADER) &&
				(field.permissions == null || showExperimentalFields) &&
				field.collapsedHeaderPlacement != null &&
				styles.includes(FIELD_STYLE_TRAILING) === trailing
			);
		})
		.sort(
			(a: IReportField, b: IReportField) => (a.collapsedHeaderPlacement ?? 0) - (b.collapsedHeaderPlacement ?? 0)
		);

export const buildGroups = (
	report: IReport,
	category: IReportCategory,
	totalWidth: number,
	showExperimentalFields = false,
	purpose: TFieldDisplayPurpose = FIELD_DISPLAY_INPUT
): TReportFieldGroup[] => {
	const filteredFields: IReportField[] = filterFields(report, category, showExperimentalFields, purpose);
	return buildGroupsHelper(filteredFields, totalWidth);
};

export const buildCollectionGroups = (
	report: IReport,
	collection: IReportCollection,
	totalWidth: number,
	showExperimentalFields = false
): TReportFieldGroup[] => {
	const filteredFields: IReportField[] = filterCollectionFields(report, collection, showExperimentalFields);
	return buildGroupsHelper(filteredFields, totalWidth);
};

export const buildGroupsHelper = (fields: IReportField[], totalWidth: number): TReportFieldGroup[] => {
	const builder: TReportFieldRowBuilderState = fields.reduce(
		(state: TReportFieldRowBuilderState, field: IReportField) => {
			const fieldWidth = field.width;
			const fieldGroup = field.group;

			if (state.currentRow.width + fieldWidth > totalWidth || state.currentRow.group !== fieldGroup) {
				state.rows.push(state.currentRow);
				state.currentRow = {
					fields: [],
					width: 0,
					group: fieldGroup
				};
			}
			state.currentRow.fields.push(field);
			state.currentRow.width += fieldWidth;

			return state;
		},
		{ rows: [], currentRow: { fields: [], width: 0, group: 1 } }
	);

	return [...builder.rows, builder.currentRow];
};

export const buildDisplayGroups = (
	report: IReport,
	category: IReportCategory,
	showExperimental = false,
	purpose: TFieldDisplayPurpose = FIELD_DISPLAY_INPUT
): TFieldDisplayGroup[] => {
	// Get the display-specific order
	const displayOrder = category.fieldsDisplayOrder;

	// Groups cache
	const groups: { [index: string]: TFieldDisplayGroup } = {};

	// Iterate over each field, bucket it in it's display group
	filterFields(report, category, showExperimental, purpose).forEach((field: IReportField) => {
		if (groups[field.displayGroup] == null) groups[field.displayGroup] = { number: field.displayGroup, fields: [] };
		groups[field.displayGroup].fields.push(field);
	});

	// Sort and build display groups
	return Object.keys(groups)
		.sort((a: string, b: string) => parseInt(a, 10) - parseInt(b, 10))
		.map((number: string) => ({
			number: parseInt(number, 10),
			fields: groups[number].fields.sort((a: IReportField, b: IReportField) => {
				return displayOrder.indexOf(a.id) - displayOrder.indexOf(b.id);
			})
		}));
};

export const getFieldDisplayWidth = (group: TFieldDisplayGroup, field: IReportField, totalWidth: number): number => {
	// Calculate field's width numerator
	const fieldDisplayWidth = field.displayWidth;

	// Calculate the group's total used width
	const usedWidth = group.fields.reduce((total: number, field: IReportField) => total + field.displayWidth, 0);
	// If the used width is >= 65% of the total width (but still less than the total), use that instead
	const rowWidth = usedWidth < totalWidth && usedWidth / totalWidth > 0.65 ? usedWidth : totalWidth;

	// Return as a percentage of the total width
	return (fieldDisplayWidth / rowWidth) * 100;
};

/*
 * General Helpers
 */

export const getFieldId = (
	field: IReportField,
	isInput = false,
	isInputChild = false,
	optionValue: string | null = null
): string => {
	// Generates a unique HTML id for a field (or it's input component, or child input component)
	return `${field.id}-${field.collectionIndex}${isInput ? "-input" : ""}${isInputChild ? "-child" : ""}${
		optionValue ? `-${optionValue}` : ""
	}`;
};

export const getFieldIdFromComponents = (fieldId: string, collectionIndex: number): string => {
	// Generates a unique HTML id for a field (or it's input component, or child input component)
	return `${fieldId}-${collectionIndex}`;
};

export const generateRange = (lower: number, upper: number, step: number): number[] => {
	// Uses the field directives to generate a range of options for numeric inputs
	if (step <= 0) {
		throw new Error("Step must be greater than 0");
	}

	const result: number[] = [];
	for (let value = lower; value <= upper; value += step) {
		result.push(value);
	}
	return result;
};
